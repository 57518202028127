"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RBlockReference = exports.RHatch = exports.REllipse = exports.RArc = exports.RCircle = exports.RText = exports.TextVertMode = exports.TextHorzMode = exports.RPoint = exports.RPolyline = exports.RTriangle = exports.RLine = exports.Shape = exports.Side = exports.From = void 0;
const ltpoint_1 = require("./ltpoint");
const ltmath_js_1 = require("./ltmath.js");
const ltlinetypepattern_js_1 = require("./ltlinetypepattern.js");
const ltentity_1 = require("./ltentity");
const ltutils_1 = require("./ltutils");
const ltcanvas_1 = require("./ltcanvas");
const ltfields_1 = require("../data/ltfields");
const ltintersection_1 = require("./ltintersection");
const exporter_1 = require("./exporter");
const converthull_1 = require("./converthull");
const RNANDOUBLE = 10000000000;
const RDEFAULT_TOLERANCE_1E_MIN4 = 1.0e-4;
var From;
(function (From) {
    From[From["FromStart"] = 1] = "FromStart";
    From[From["FromEnd"] = 2] = "FromEnd";
    From[From["FromAny"] = 3] = "FromAny"; /**< Start or end **/
    From[From["AlongPolyline"] = 4] = "AlongPolyline";
})(From || (exports.From = From = {}));
;
var Side;
(function (Side) {
    Side[Side["NoSide"] = 0] = "NoSide";
    Side[Side["LeftHand"] = 1] = "LeftHand";
    Side[Side["RightHand"] = 2] = "RightHand";
    Side[Side["BothSides"] = 3] = "BothSides";
})(Side || (exports.Side = Side = {}));
;
class Shape {
    constructor() {
        this.position = new ltpoint_1.LTPoint(0, 0);
        this._rotate = 0;
    }
    setRotate(rotate) {
        if (isNaN(rotate)) {
            return;
        }
        this._rotate = rotate * 1;
    }
    getRotate() {
        return this._rotate;
    }
    getShapeType() {
        return ltutils_1.ShapeType.Unknown;
    }
    getShapeName() {
        return "";
    }
    getEntityName() {
        return "";
    }
    mirror(axis) {
    }
    reverse() {
        return false;
    }
    isDirected() {
        return false;
    }
    moveReferencePoint(referencePoint, targetPoint) {
        return true;
    }
    getReferencePoints() {
        return [];
    }
    getSideOfPoint(point) {
        return Side.NoSide;
    }
    //获取凸包;
    getHull() {
        let bounding = this.getBoundingBox();
        return ltutils_1.LTUtils.boxToPolyline(bounding);
    }
    static isFullEllipseShape(s) {
        if (s instanceof REllipse) {
            return s.getShapeType() == ltutils_1.ShapeType.Ellipse && s.isFullEllipse();
        }
        return false;
    }
    static isFullCircleShape(s) {
        if (s instanceof RArc) {
            return s.getShapeType() == ltutils_1.ShapeType.Arc && s.isFullCircle();
        }
        return false;
    }
    //通过点分割;
    splitAt(points) {
        return [];
    }
    //通过百分比获取线上的点;
    getPointAtPercent(p) {
        let length = this.getLength();
        let distance = p * length;
        let candidates = this.getPointsWithDistanceToEnd(distance, From.FromStart);
        if (candidates.length != 1) {
            return null;
        }
        return candidates[0];
    }
    //获取当前点到七点的距离;
    getDistanceFromStart(p) {
        return RNANDOUBLE;
    }
    getVectorFromEndpointTo(point) {
        var endPoints = this.getEndPoints();
        var closest = point.getClosest(endPoints);
        if (closest === null) {
            return point;
        }
        return point.minus(closest);
    }
    contains(p) {
        //子类自行判断
        return true;
    }
    getBoundingBox() {
        return ltpoint_1.RBox.nullBox();
    }
    isEllipseShape() {
        return this.getShapeType() === ltutils_1.ShapeType.Ellipse;
    }
    getEndPoints() {
        return [];
    }
    getMiddlePoint() {
        return this.position || new ltpoint_1.LTPoint(0, 0);
    }
    getEndPointsByBox(query_box) {
        var ret = [];
        var shapes = this.getShapes(query_box, true);
        for (var i = 0; i < shapes.length; i++) {
            let end = shapes[i].getEndPoints();
            if (end.length > 0) {
                ret = ret.concat(end);
            }
        }
        return ret;
    }
    getMiddlePoints() {
        return [];
    }
    getCenterPoints() {
        return [];
    }
    getStartPoint() {
        return {};
    }
    getMiddlePointsByBox(query_box) {
        var ret = [];
        var shapes = this.getShapes(query_box, true);
        for (var i = 0; i < shapes.length; i++) {
            let mid = shapes[i].getMiddlePoints();
            if (mid.length > 0) {
                ret = ret.concat(mid);
            }
        }
        return ret;
    }
    getShapes(query_box, ignoreComplex = false, issegment = false) {
        return [];
    }
    getAngleAt(distance, from = From.FromStart) {
        return RNANDOUBLE;
    }
    getCenterPointsByBox(query_box) {
        var ret = [];
        var shapes = this.getShapes(query_box, true);
        for (var i = 0; i < shapes.length; i++) {
            let center = shapes[i].getCenterPoints();
            if (center.length > 0) {
                ret = ret.concat(center);
            }
        }
        return ret;
    }
    cloneFrom(shape) {
        shape._rotate = this._rotate;
    }
    getClosestShape(pos, range, ignoreComplex) {
        var ret = null;
        var shapes = [];
        if (range > 1000000000) {
            shapes = this.getShapes(null, ignoreComplex, false);
        }
        else {
            shapes = this.getShapes(ltpoint_1.RBox.fromCenter(pos, range), ignoreComplex, false);
        }
        // entity not based on shape:
        if (shapes.length == 0) {
            return ret;
        }
        // single shape:
        if (shapes.length == 1) {
            return shapes[0];
        }
        var minDistance = RNANDOUBLE;
        for (var i = 0; i < shapes.length; i++) {
            var shape = shapes[i];
            var subShapes = [];
            subShapes.push(shape.clone());
            for (var k = 0; k < subShapes.length; ++k) {
                var shape2 = subShapes[k];
                var distance = shape2.getDistanceTo(pos);
                if (distance < minDistance) {
                    minDistance = distance;
                    ret = shape2;
                }
            }
        }
        return ret;
    }
    getClosestPointOnShape(p, limited = false, strictRange = RNANDOUBLE) {
        if (p == null) {
            return p;
        }
        var dv = this.getVectorTo(p, limited, strictRange);
        if (dv == null) {
            return null;
        }
        return p.minus(dv);
    }
    getPointOnShape() {
        var midPoints = this.getMiddlePoints();
        if (midPoints.length > 0) {
            return midPoints[0];
        }
        var endPoints = this.getEndPoints();
        if (endPoints.length > 0) {
            return endPoints[0];
        }
        return this.getClosestPointOnShape({});
    }
    getIntersectionPointsByBox(other_shape, limited = true, is_same = false, query_box) {
        var ret = [];
        var shapes1 = this.getShapes(query_box, limited);
        var shapes2 = other_shape.getShapes(query_box, limited);
        for (var i = 0; i < shapes1.length; ++i) {
            var kstart = 0;
            if (is_same) {
                kstart = i + 1;
            }
            for (var k = kstart; k < shapes2.length; ++k) {
                if (i * k > 100) //避免循环过多
                 {
                    return ret; // return
                }
                var ips = ltintersection_1.LTIntersection.getIntersectionPoints(shapes1[i], shapes2[k], limited, false);
                ret = ret.concat(ips);
            }
        }
        return ret;
    }
    static getReversedShapeList(shapes) {
        let ret = [];
        for (let i = shapes.length - 1; i >= 0; i--) {
            let shape = shapes[i].clone();
            shape.reverse();
            ret.push(shape);
        }
        return ret;
    }
    static getOffsetLines(shape, distance, num, side, position) {
        let ret = [];
        if (!shape.isDirected()) {
            return ret;
        }
        let sides = [];
        if (position.isValid()) {
            sides.push(shape.getSideOfPoint(position));
        }
        else {
            if (side == Side.BothSides) {
                sides.push(Side.LeftHand);
                sides.push(Side.RightHand);
            }
            else {
                sides.push(side);
            }
        }
        for (let i = 0; i < sides.length; i++) {
            let side = sides[i];
            let a = 0;
            if (side == Side.LeftHand) {
                a = shape.getDirection1() + Math.PI / 2.0;
            }
            else {
                a = shape.getDirection1() - Math.PI / 2.0;
            }
            let distanceV = new ltpoint_1.LTPoint(0, 0);
            for (let n = 1; n <= num; ++n) {
                distanceV.setPolar(distance * n, a);
                let parallel = shape.clone();
                parallel.move(distanceV);
                ret.push(parallel);
            }
        }
        return ret;
    }
    static getOffsetArcs(shape, distance, num, side, position) {
        let ret = [];
        let arc = shape;
        let circle = shape;
        let center;
        let radius;
        let reversed;
        if (arc) {
            center = arc.getCenter();
            radius = arc.getRadius();
            reversed = arc.isReversed;
        }
        else if (circle) {
            center = circle.getCenter();
            radius = circle.getRadius();
            reversed = false;
        }
        else {
            return ret;
        }
        let insides = [];
        if (position.isValid()) {
            let is_inside = center.getDistanceTo(position) < radius;
            insides.push(is_inside);
        }
        else {
            if (side == Side.BothSides) {
                insides.push(true);
                insides.push(false);
            }
            else {
                if (!reversed) {
                    if (side == Side.LeftHand) {
                        insides.push(true);
                    }
                    else {
                        insides.push(false);
                    }
                }
                else {
                    if (side == Side.RightHand) {
                        insides.push(true);
                    }
                    else {
                        insides.push(false);
                    }
                }
            }
        }
        for (let i = 0; i < insides.length; i++) {
            let inside = insides[i];
            let d = distance;
            if (inside) {
                d *= -1;
            }
            for (let n = 1; n <= num; ++n) {
                let s = shape.clone();
                if (arc) {
                    let concentric = s;
                    concentric.radius = concentric.radius + d * n;
                    if (concentric.radius < 0.0) {
                        break;
                    }
                }
                if (circle) {
                    let concentric = s;
                    concentric.setRadius(concentric.getRadius() + d * n);
                    if (concentric.getRadius() < 0.0) {
                        break;
                    }
                    ret.push(s);
                }
            }
        }
        return ret;
    }
    isOnShape(point, limited = true, tolerance = RDEFAULT_TOLERANCE_1E_MIN4) {
        var d = this.getDistanceTo(point, limited);
        return d < tolerance;
    }
    getDistanceTo(point, limited = true, strictRange = RNANDOUBLE) {
        var v = this.getVectorTo(point, limited, strictRange);
        if (v != null) {
            return v.getMagnitude();
        }
        return strictRange;
    }
    getClosestPointOnEntity(point, range, limited) {
        var ret = null;
        var minDist = -1;
        var shapes = this.getShapes(null, true, false);
        for (var i = 0; i < shapes.length; ++i) {
            var r = shapes[i].getClosestPointOnShape(point, limited);
            if (r == null) {
                continue;
            }
            var dist = r.getDistanceTo(point);
            if (ret == null || minDist < -1 || dist < minDist) {
                ret = r;
                minDist = dist;
            }
        }
        if (range < 0) {
            return ret;
        }
        //添加限定;
        if (minDist < 0 || minDist > range) {
            return null;
        }
        return ret;
    }
    getIntersectionPoints(shape2, limited, is_same = false) {
        return ltintersection_1.LTIntersection.getIntersectionPoints(this, shape2, limited, is_same);
    }
    intersectsWith(other, limited) {
        return this.getIntersectionPoints(other, limited).length != 0;
    }
    move(offset) { }
    rotate(rotation, center) { }
    scale(factors, center) {
    }
    getTrimEnd(trimPoint, clickPoint) {
        return false;
    }
    trimStartPoint(trimPoint, clickPoint, extend = false) {
        return true;
    }
    trimEndPoint(trimPoint, clickPoint, extend = false) {
        return true;
    }
    toWkt() {
        let wkt = "MultiPoint ((0 0))";
        return wkt;
    }
    getSaveValue() {
        return [];
    }
}
exports.Shape = Shape;
class RLine extends Shape {
    constructor(start_point, end_point) {
        super();
        this.index = 0;
        this._start_point = start_point;
        this._end_point = end_point;
    }
    static createAngle(startPoint, angle, distance) {
        let endPoint = startPoint.plus(ltpoint_1.LTPoint.createPolar(distance, angle));
        return new RLine(startPoint, endPoint);
    }
    isValid() {
        return this._start_point.isSane() && this._end_point.isSane();
    }
    setLength(l, fromStart) {
        if (fromStart) {
            this._end_point = this._start_point.plus(ltpoint_1.LTPoint.createPolar(l, this.getAngle()));
        }
        else {
            this._start_point = this._end_point.minus(ltpoint_1.LTPoint.createPolar(l, this.getAngle()));
        }
    }
    mirror(axis) {
        this._start_point.mirror(axis);
        this._end_point.mirror(axis);
    }
    getDistanceFromStart(p) {
        let ret = this._start_point.getDistanceTo(p);
        let p2 = this.getClosestPointOnShape(p, false);
        if (p2 == null) {
            return 0;
        }
        let angle = this._start_point.getAngleTo(p2);
        if (ltmath_js_1.LTMath.isSameDirection(this.getAngle(), angle, Math.PI / 2)) {
            return ret;
        }
        else {
            return -ret;
        }
    }
    getHull() {
        let result_points = [];
        result_points.push(this._start_point);
        result_points.push(this._end_point);
        return (0, converthull_1.convertHull)(result_points);
    }
    getExploded() {
        var ret = [];
        ret.push(this);
        return ret;
    }
    getShapeType() {
        return ltutils_1.ShapeType.Line;
    }
    getShapeName() {
        return "线";
    }
    getEntityName() {
        return "AcDbline";
    }
    getDirection1() {
        return this._start_point.getAngleTo(this._end_point);
    }
    getDirection2() {
        return this._end_point.getAngleTo(this._start_point);
    }
    clone() {
        var shape1 = new RLine(this._start_point.clone(), this._end_point.clone());
        return shape1;
    }
    getShapes(query_box, ignoreComplex, issegment) {
        var ret = [];
        if (query_box != null) {
            if (!query_box.intersects(this.getBoundingBox())) {
                return ret;
            }
        }
        ret.push(this);
        return ret;
    }
    getAngleAt(distance, from) {
        return this.getAngle();
    }
    splitAt(points) {
        let ret = [];
        let sortedPoints = ltpoint_1.LTPoint.getSortedByDistance(points, this._start_point);
        if (!this._start_point.equalsFuzzy(sortedPoints[0])) {
            sortedPoints.unshift(this._start_point);
        }
        if (!this._end_point.equalsFuzzy(sortedPoints[sortedPoints.length - 1])) {
            sortedPoints.push(this._end_point);
        }
        for (let i = 0; i < sortedPoints.length - 1; i++) {
            if (sortedPoints[i].equalsFuzzy(sortedPoints[i + 1])) {
                continue;
            }
            ret.push(new RLine(sortedPoints[i], sortedPoints[i + 1]));
        }
        return ret;
    }
    //判断是否为直线;
    isDirected() {
        return true;
    }
    moveReferencePoint(referencePoint, targetPoint) {
        if (this._start_point.equalsFuzzy(referencePoint) == true) {
            this._start_point = targetPoint;
            return true;
        }
        if (this._end_point.equalsFuzzy(referencePoint) == true) {
            this._end_point = targetPoint;
            return true;
        }
        let mid = this.getMiddlePoint();
        if (mid.equalsFuzzy(referencePoint) == true) {
            let offset = targetPoint.minus(referencePoint);
            this._start_point.x += offset.x;
            this._start_point.y += offset.y;
            this._end_point.x += offset.x;
            this._end_point.y += offset.y;
            return true;
        }
        //中间点
        return false;
    }
    getMiddlePoint() {
        let ret = new ltpoint_1.LTPoint((this._start_point.x + this._end_point.x) / 2, (this._start_point.y + this._end_point.y) / 2);
        ret.flag = ltpoint_1.Flag.Secondary;
        return ret;
    }
    getVectorTo(point, limited, strictRange = RNANDOUBLE) {
        var ae = this._end_point.minus(this._start_point);
        var ap = point.minus(this._start_point);
        if (ae.getMagnitude() < 1.0e-6) {
            return null;
        }
        if (ap.getMagnitude() < 1.0e-6) {
            // distance to start point is very small:
            return new ltpoint_1.LTPoint(0, 0);
        }
        var b = ltpoint_1.LTPoint.getDotProduct(ap, ae) / ltpoint_1.LTPoint.getDotProduct(ae, ae);
        if (limited && (b < 0 || b > 1.0)) {
            // orthogonal to line does not cross line, use distance to end point:
            var ret = this.getVectorFromEndpointTo(point);
            if (ret.getMagnitude() < strictRange) {
                return ret;
            }
            else {
                // not within given range:
                return null;
            }
        }
        var closestPoint = this._start_point.plus(ae.dot(b));
        return point.minus(closestPoint);
    }
    getEndPoints() {
        var ret = [];
        ret.push(this._start_point);
        ret.push(this._end_point);
        return ret;
    }
    getMiddlePoints() {
        var ret = [];
        ret.push(this.getMiddlePoint());
        return ret;
    }
    getCenterPoints() {
        return this.getMiddlePoints();
    }
    getBoundingBox() {
        return ltpoint_1.RBox.formVectors(ltpoint_1.LTPoint.getMinimun(this._start_point, this._end_point), ltpoint_1.LTPoint.getMaximun(this._start_point, this._end_point));
    }
    getPointCloud(segment_length) {
        var ret = [];
        for (var d = segment_length; d < this.getLength(); d += segment_length) {
            let p = this.getPointWithDistanceToStart(d);
            if (p != null) {
                ret.push(p);
            }
        }
        ret.push(this._end_point);
        return ret;
    }
    getPointsWithDistanceToEnd(distance, from = From.FromAny) {
        var ret = [];
        if (from & From.FromStart) {
            var start = this._end_point.minus(this._start_point).getNormalized();
            ret.push(this._start_point.plus(start.dot(distance)));
        }
        if (from & From.FromEnd) {
            var end = this._start_point.minus(this._end_point).getNormalized();
            ret.push(this._end_point.plus(end.dot(distance)));
        }
        return ret;
    }
    getPointWithDistanceToStart(distance) {
        var res = this.getPointsWithDistanceToEnd(distance, From.FromStart | From.AlongPolyline);
        if (res.length == 0) {
            return null;
        }
        return res[0];
    }
    getAngle() {
        return this._start_point.getAngleTo(this._end_point);
    }
    setAngle(a) {
        this._end_point = this._start_point.plus(ltpoint_1.LTPoint.createPolar(this.getLength(), a));
    }
    getLength() {
        return this._start_point.getDistanceTo(this._end_point);
    }
    //判断点在线的位置;
    getSideOfPoint(point) {
        var entityAngle = this.getAngle();
        var angleToCoord = this._start_point.getAngleTo(point);
        var angleDiff = ltmath_js_1.LTMath.getAngleDifference(entityAngle, angleToCoord);
        if (angleDiff < Math.PI) {
            return Side.LeftHand;
        }
        return Side.RightHand;
    }
    getStartPoint() {
        return this._start_point;
    }
    setStartPoint(p) {
        this._start_point = p;
    }
    getEndPoint() {
        return this._end_point;
    }
    move(offset) {
        this._start_point.move(offset.x, offset.y);
        this._end_point.move(offset.x, offset.y);
    }
    rotate(rotation, center) {
        this._start_point.rotate(rotation, center);
        this._end_point.rotate(rotation, center);
    }
    scale(scale_factors, c = null) {
        this._start_point.scale(scale_factors.x, scale_factors.y, c);
        this._end_point.scale(scale_factors.x, scale_factors.y, c);
    }
    //trim相关功能
    getTrimEnd(trimPoint, clickPoint) {
        var lineAngle = this.getAngle();
        var angleToClickPoint = trimPoint.getAngleTo(clickPoint);
        var angleDifference = lineAngle - angleToClickPoint;
        if (angleDifference < 0.0) {
            angleDifference *= -1.0;
        }
        if (angleDifference > Math.PI) {
            angleDifference = 2 * Math.PI - angleDifference;
        }
        if (angleDifference < Math.PI / 2.0) {
            return false;
        }
        return true;
    }
    trimStartPoint(trimPoint, clickPoint, extend) {
        var tp = this.getClosestPointOnShape(trimPoint, false);
        if (tp == null) {
            return false;
        }
        this._start_point = tp;
        return true;
    }
    trimEndPoint(trimPoint, clickPoint, extend) {
        var tp = this.getClosestPointOnShape(trimPoint, false);
        if (tp == null) {
            return false;
        }
        this.setEndPoint(tp);
        return true;
    }
    setEndPoint(point) {
        this._end_point = point;
    }
    getReferencePoints() {
        let ret = [];
        ret.push(this._start_point);
        ret.push(this.getMiddlePoint());
        ret.push(this._end_point);
        return ret;
    }
    getSaveValue() {
        let dict = [];
        let poss = [];
        poss.push(this._start_point);
        poss.push(this._end_point);
        let str = ltpoint_1.LTPoints.toJson(poss);
        let pjson = {};
        pjson["points"] = str;
        dict[ltfields_1.FieldNames.Cadgeo] = JSON.stringify(pjson);
        return dict;
    }
}
exports.RLine = RLine;
class RTriangle extends Shape {
    constructor(p1, p2, p3) {
        super();
        this._corner = [];
        this._corner.push(p1);
        this._corner.push(p2);
        this._corner.push(p3);
    }
    get points() {
        return this._corner;
    }
    getVectorTo(point, limited, strictRange) {
        let l1 = new RLine(this._corner[0], this._corner[1]);
        let l2 = new RLine(this._corner[1], this._corner[2]);
        let l3 = new RLine(this._corner[2], this._corner[0]);
        let v1 = l1.getVectorTo(point, limited, strictRange);
        let v2 = l2.getVectorTo(point, limited, strictRange);
        let v3 = l3.getVectorTo(point, limited, strictRange);
        let m1 = 0;
        if (v1 != null) {
            m1 = v1.getMagnitude();
        }
        let m2 = 0;
        if (v2 != null) {
            m2 = v2.getMagnitude();
        }
        let m3 = 0;
        if (v3 != null) {
            m3 = v3.getMagnitude();
        }
        if (m1 < m2 && m1 < m3) {
            return v1;
        }
        else if (m2 < m3) {
            return v2;
        }
        else {
            return v3;
        }
    }
    getPointsWithDistanceToEnd(distance, from) {
        let result_points = [];
        let l1 = new RLine(this._corner[0], this._corner[1]);
        let l2 = new RLine(this._corner[1], this._corner[2]);
        let l3 = new RLine(this._corner[2], this._corner[0]);
        result_points.push(...l1.getPointsWithDistanceToEnd(distance));
        result_points.push(...l2.getPointsWithDistanceToEnd(distance));
        result_points.push(...l3.getPointsWithDistanceToEnd(distance));
        return result_points;
    }
    getEndPoint() {
        return this._corner[0];
    }
    clone() {
        return new RTriangle(this._corner[0].clone(), this._corner[1].clone(), this._corner[1].clone());
    }
    getExploded() {
        let result = [];
        for (let i = 0; i < 3; i++) {
            result.push(new RLine(this._corner[i], this._corner[(i + 1) % 3]));
        }
        return result;
    }
    getLength() {
        return this._corner[0].getDistanceTo(this._corner[1]) +
            this._corner[1].getDistanceTo(this._corner[2]) +
            this._corner[2].getDistanceTo(this._corner[0]);
    }
    isDirected() {
        return true;
    }
    getDirection1() {
        return 0;
    }
    getDirection2() {
        return 0;
    }
    getShapeName() {
        return "三角形";
    }
}
exports.RTriangle = RTriangle;
class RPolyline extends Shape {
    constructor(vertices) {
        super();
        this._vertices = [];
        this._bulges = [];
        this._start_widths = [];
        this._end_widths = [];
        this._isPolygion = false;
        //是否为区域覆盖;
        this._wipeout = false;
        this.setVertices(vertices);
    }
    setVertices(vertices) {
        this._vertices = vertices;
        this._bulges = [];
        for (let i = 0; i < vertices.length; ++i) {
            this._bulges.push(0.0);
        }
    }
    setClose() {
        if (this._vertices.length <= 1) {
            return;
        }
        if (this.isClosed() == false) {
            this._vertices.push(this._vertices[0]);
            this._bulges.push(0);
        }
    }
    getHull() {
        let shapes = this.getShapes(null, true);
        let result_points = [];
        if (this.hasArcSegments()) {
            for (let i = 0; i < shapes.length; i++) {
                let polygon = shapes[i].getHull();
                result_points = result_points.concat(polygon.subData()._vertices);
            }
            return (0, converthull_1.convertHull)(result_points);
        }
        result_points = this._vertices;
        return (0, converthull_1.convertHull)(result_points);
    }
    isClosed() {
        if (this._vertices[0].equals(this._vertices[this._vertices.length - 1])) {
            return true;
        }
        return false;
    }
    getShapeName() {
        return "线";
    }
    getEntityName() {
        if (this.isWipeOut) {
            return "AcDbWipeout";
        }
        return "AcDbPolyline";
    }
    hasArcSegments() {
        for (let i = 0; i < this._bulges.length; i++) {
            if (!this.isStraight(this._bulges[i])) {
                return true;
            }
        }
        return false;
    }
    getBulgeAt(i) {
        if (i < 0 || i >= this._bulges.length) {
            return RNANDOUBLE;
        }
        return this._bulges[i];
    }
    get isWipeOut() {
        return this._wipeout;
    }
    set isWipeOut(value) {
        if (value) {
            this.setClose();
        }
        this._wipeout = value;
    }
    setBulgeAt(i, b) {
        if (i < 0 || i >= this._bulges.length) {
            return;
        }
        this._bulges[i] = b;
    }
    clone() {
        let sub_points = [];
        this._vertices.forEach(e => sub_points.push(e.clone()));
        let rline = new RPolyline(sub_points);
        rline._wipeout = this._wipeout;
        for (let i = 0; i < this._vertices.length; ++i) {
            rline._bulges[i] = this._bulges[i];
        }
        for (let i = 0; i < this._start_widths.length; ++i) {
            rline._start_widths[i] = this._start_widths[i];
        }
        for (let i = 0; i < this._end_widths.length; ++i) {
            rline._end_widths[i] = this._end_widths[i];
        }
        rline._isPolygion = this._isPolygion;
        return rline;
    }
    getShapeType() {
        return ltutils_1.ShapeType.Polyline;
    }
    isDirected() {
        return true;
    }
    getDirection1() {
        if (this._vertices.length == 0) {
            return RNANDOUBLE;
        }
        let shape = this.getSegmentAt(0);
        return shape.getDirection1();
    }
    getDirection2() {
        if (this._vertices.length == 0) {
            return RNANDOUBLE;
        }
        let i = this._vertices.length - 2;
        if (this.isClosed()) {
            i++;
        }
        let shape = this.getSegmentAt(i);
        if (!shape) {
            return RNANDOUBLE;
        }
        return shape.getDirection2();
    }
    getSideOfPoint(point) {
        let i = this.getClosestSegment(point);
        if (i < 0 || i >= this.countSegments()) {
            return Side.NoSide;
        }
        let segment = this.getSegmentAt(i);
        if (!segment) {
            return Side.NoSide;
        }
        return segment.getSideOfPoint(point);
    }
    getDistanceFromStart(p) {
        let res = this.getDistancesFromStart(p);
        if (res.length == 0) {
            return RNANDOUBLE;
        }
        return res[0];
    }
    getDistancesFromStart(p) {
        let ret = [];
        let len = 0.0;
        for (let i = 0; i < this.countSegments(); i++) {
            let segment = this.getSegmentAt(i);
            if (segment.getDistanceTo(p) < 0.0001) {
                ret.push(len + segment.getDistanceFromStart(p));
            }
            len += segment.getLength();
        }
        if (ret.length == 0) {
            ret.push(this.getLengthTo(p, true));
        }
        return ret;
    }
    clear() {
        this._vertices = [];
        this._bulges = [];
    }
    getEndPoint() {
        if (this.isClosed()) {
            return this._vertices[0];
        }
        return this._vertices[this._vertices.length - 1];
    }
    getStartPoint() {
        return this._vertices[0];
    }
    getMiddlePoint() {
        var pts = this.getPointsWithDistanceToEnd(this.getLength() / 2, From.FromStart);
        return pts[0];
    }
    getPointsWithDistanceToEnd(distance, from = From.FromAny) {
        let ret = [];
        let sub = this.getExploded();
        if (sub.length == 0) {
            return ret;
        }
        if (from & From.AlongPolyline) {
            let remainingDist = 0;
            let len = 0;
            if (from & From.FromStart) {
                if (distance < 0.0) {
                    // extend at start:
                    let sub_points = sub[0].getPointsWithDistanceToEnd(distance, From.FromStart);
                    ret = ret.concat(sub_points);
                }
                else {
                    remainingDist = distance;
                    for (let i = 0; i < sub.length; i++) {
                        len = sub[i].getLength();
                        if (remainingDist > len) {
                            remainingDist -= len;
                        }
                        else {
                            let sub_points = sub[i].getPointsWithDistanceToEnd(remainingDist, From.FromStart);
                            ret = ret.concat(sub_points);
                            break;
                        }
                    }
                }
            }
            if (from & From.FromEnd) {
                if (distance < 0.0) {
                    // extend at end:
                    let sub_points = sub[sub.length - 1].getPointsWithDistanceToEnd(distance, From.FromEnd);
                    ret = ret.concat(sub_points);
                }
                else {
                    remainingDist = distance;
                    for (let i = sub.length - 1; i >= 0; i--) {
                        len = sub[i].getLength();
                        if (remainingDist > len) {
                            remainingDist -= len;
                        }
                        else {
                            let sub_points = sub[i].getPointsWithDistanceToEnd(remainingDist, From.FromEnd);
                            ret = ret.concat(sub_points);
                            break;
                        }
                    }
                }
            }
        }
        else {
            sub.forEach(e => {
                ret = ret.concat(e.getPointsWithDistanceToEnd(distance, from));
            });
        }
        return ret;
    }
    reverse() {
        let vs = this._vertices;
        let nPolyline = new RPolyline([]);
        for (let i = vs.length - 1, k = 0; i >= 0; i--, k++) {
            nPolyline.appendVertex(vs[i], 0);
            if (i > 0) {
                nPolyline.setBulgeAt(k, -this._bulges[i - 1]);
            }
        }
        return true;
    }
    hasWidths() {
        for (let i = 0; i < this._start_widths.length && i < this._end_widths.length; i++) {
            if (!isNaN(this._start_widths[i]) && this._start_widths[i] > 0.0) {
                if (i != this._start_widths.length - 1 || this.isClosed()) {
                    return true;
                }
            }
            if (!isNaN(this._end_widths[i]) && this._end_widths[i] > 0.0) {
                if (i != this._start_widths.length - 1 || this.isClosed()) {
                    return true;
                }
            }
        }
        return false;
    }
    get startWidths() {
        return this._start_widths;
    }
    set startWidths(sw) {
        this._start_widths = sw;
    }
    set endWidths(ew) {
        this._end_widths = ew;
    }
    triangulateLine(index) {
        let p1 = this._vertices[index];
        let count = this._vertices.length;
        let p2 = this._vertices[(index + 1) % count];
        let sw = this.startWidths[index];
        let ew = this.endWidths[index];
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        let length = p1.getDistanceTo2D(p2);
        let nx = -dy / length;
        let ny = dx / length;
        let p1a = new ltpoint_1.LTPoint(p1.x + sw * nx, p1.y + sw * ny);
        let p1b = new ltpoint_1.LTPoint(p1.x - sw * nx, p1.y - sw * ny);
        let p2a = new ltpoint_1.LTPoint(p2.x + ew * nx, p2.y + ew * ny);
        let p2b = new ltpoint_1.LTPoint(p2.x - ew * nx, p2.y - ew * ny);
        let ret = [];
        ret.push(new RTriangle(p1a, p1b, p2a));
        ret.push(new RTriangle(p2a, p2b, p1b));
        return ret;
    }
    get endWidths() {
        return this._end_widths;
    }
    appendShape(shape, prepend = false) {
        let ret = true;
        // append spline as polyline approximation:
        if (shape.getShapeType() == ltutils_1.ShapeType.Spline) {
            // const RSpline* spl = dynamic_cast<const RSpline*>(&shape);
            // if (spl!=NULL) {
            //     double tol = RSettings::getDoubleValue("Explode/SplineTolerance", 0.01);
            //     RPolyline pl = spl->approximateWithArcs(tol);
            //     return appendShape(pl, prepend);
            // }
        }
        // append ellipse as polyline approximation:
        else if (shape.getShapeType() == ltutils_1.ShapeType.Ellipse) {
            // if(shape instanceof REllipse)
            // {
            // }
            //   const REllipse* elp = dynamic_cast<const REllipse*>(&shape);
            //   if (elp!=NULL) {
            //       double seg = RSettings::getDoubleValue("Explode/EllipseSegments", 32);
            //       RPolyline pl = elp->approximateWithArcs(seg);
            //       return appendShape(pl, prepend);
            //   }
        }
        // append circle as polyline to empty polyline:
        else if (shape.getShapeType() == ltutils_1.ShapeType.Circle && this.countVertices() == 0) {
            if (shape instanceof RCircle) {
                this.appendShape(new RArc(shape.getCenter(), shape.getRadius(), 0.0, Math.PI, false));
                this.appendShape(new RArc(shape.getCenter(), shape.getRadius(), Math.PI, 2 * Math.PI, false));
                return true;
            }
        }
        // append full circle arc as circle (two arc segments) to empty polyline:
        else if (shape.getShapeType() == ltutils_1.ShapeType.Arc) {
            if (shape instanceof RArc) {
                if (shape.isFullCircle()) {
                    this.appendShape(new RCircle(shape.getCenter(), shape.getRadius()));
                    return true;
                }
            }
        }
        // append polyline:
        else if (shape.getShapeType() == ltutils_1.ShapeType.Polyline) {
            if (shape instanceof RPolyline) {
                if (prepend) {
                    for (let i = shape.countSegments() - 1; i >= 0; --i) {
                        let s = shape.getSegmentAt(i);
                        if (s === null) {
                            continue;
                        }
                        ret = this.appendShape(s, true) && ret;
                    }
                }
                else {
                    for (let i = 0; i < shape.countSegments(); ++i) {
                        let s = shape.getSegmentAt(i);
                        if (s === null) {
                            continue;
                        }
                        ret = this.appendShape(s) && ret;
                    }
                }
                return ret;
            }
        }
        let bulge = 0.0;
        if (shape instanceof RArc) {
            bulge = shape.getBulge();
        }
        let connectionPoint;
        let nextPoint;
        let gap;
        if (prepend) {
            // prepend:
            connectionPoint = shape.getEndPoint();
            nextPoint = shape.getStartPoint();
            if (this._vertices.length == 0) {
                // first point:
                this.appendVertex(connectionPoint);
            }
            gap = this._vertices[0].getDistanceTo(connectionPoint);
        }
        else {
            // append:
            connectionPoint = shape.getStartPoint();
            nextPoint = shape.getEndPoint();
            if (this._vertices.length == 0) {
                // first point:
                this.appendVertex(connectionPoint);
            }
            gap = this._vertices[this.countVertices() - 1].getDistanceTo(connectionPoint);
        }
        if (!ltmath_js_1.LTMath.fuzzyCompare(gap, 0.0, 1.0e-3)) {
            ret = false;
        }
        if (prepend) {
            this.prependVertex(nextPoint);
            this.setBulgeAt(0, bulge);
        }
        else {
            this.appendVertex(nextPoint);
            this.setBulgeAt(this._bulges.length - 2, bulge);
        }
        return ret;
    }
    appendVertex(vertex, bulge = 0, start_width = 0, end_width = 0) {
        this._vertices.push(vertex);
        this._bulges.push(bulge);
        this._start_widths.push(start_width);
        this._end_widths.push(end_width);
    }
    prependVertex(vertex, bulge = 0, start_width = 0, end_width = 0) {
        this._vertices.unshift(vertex);
        this._bulges.unshift(bulge);
        this._start_widths.unshift(start_width);
        this._end_widths.unshift(end_width);
    }
    removeLastVertex() {
        this._vertices.pop();
        this._bulges.pop();
    }
    contain(shape) {
        if (!this.isClosed) {
            return false;
        }
        if (shape instanceof RPolyline) {
            for (let i = 0; i < shape._vertices.length; ++i) {
                if (!ltutils_1.LTUtils.containsByPoint(this._vertices, shape._vertices[i])) {
                    return false;
                }
            }
            return true;
        }
        else if (shape instanceof RLine) {
            if (ltutils_1.LTUtils.containsByPoint(this._vertices, shape.getStartPoint()) && ltutils_1.LTUtils.containsByPoint(this._vertices, shape.getEndPoint())) {
                return true;
            }
            return false;
        }
        let shape_box = shape.getBoundingBox();
        let points = [];
        points.push(shape_box.getMinimum());
        points.push(new ltpoint_1.LTPoint(shape_box.getMinimum().x + shape_box.getWidth(), shape_box.getMinimum().y));
        points.push(new ltpoint_1.LTPoint(shape_box.getMinimum().x + shape_box.getWidth(), shape_box.getMaximum().y));
        points.push(new ltpoint_1.LTPoint(shape_box.getMinimum().x, shape_box.getMaximum().y));
        for (let i = 0; i < points.length; ++i) {
            if (!ltutils_1.LTUtils.containsByPoint(this._vertices, points[i])) {
                return false;
            }
        }
        return true;
    }
    insertVertex(index, vertex) {
        this._vertices.splice(index, 0, vertex);
    }
    insertVertexAt(point) { }
    countVertices() {
        return this._vertices.length;
    }
    //获取离当前点最近的先得一个端点;
    getClosestSegment(point) {
        var ret = -1;
        var minDist = -1;
        for (var i = 0; i < this.countSegments(); i++) {
            var segment = this.getSegmentAt(i);
            if (segment == null) {
                break;
            }
            var dist = segment.getDistanceTo(point, true);
            if (minDist < 0 || dist < minDist) {
                minDist = dist;
                ret = i;
            }
        }
        return ret;
    }
    getArea() {
        if (this._vertices.length < 2) {
            return 0;
        }
        let dArea = 0.0;
        for (let i = 0; i < this._vertices.length; ++i) {
            if (this._vertices.length - 1 == i) {
                dArea += 0.5 * (this._vertices[i].x + this._vertices[0].x) * (this._vertices[i].y - this._vertices[0].y);
            }
            else {
                dArea += 0.5 * (this._vertices[i].x + this._vertices[i + 1].x) * (this._vertices[i].y - this._vertices[i + 1].y);
            }
        }
        return Math.abs(dArea);
    }
    splitAt(points) {
        let ret = [];
        let shapes = this.getShapes(null);
        let start_shape = null;
        let start_index = 0;
        for (let i = 0; i < points.length; ++i) {
            let index = this.getClosesetSegment(points[i], start_index);
            let split_points = [];
            split_points.push(points[i]);
            let sub_shapes = shapes[index].splitAt(split_points);
            if (sub_shapes.length == 2) {
                let new_polyline = new RPolyline([]);
                if (start_shape !== null) {
                    new_polyline.appendShape(start_shape);
                }
                for (let k = start_index; k < index; ++k) {
                    new_polyline.appendShape(shapes[k]);
                }
                new_polyline.appendShape(sub_shapes[0]);
                ret.push(new_polyline);
                start_shape = sub_shapes[1];
            }
            start_index = index;
        }
        //加上最后一个;
        if (start_shape !== null) {
            let new_polyline = new RPolyline([]);
            new_polyline.appendShape(start_shape);
            for (let k = start_index + 1; k < this.countSegments(); ++k) {
                new_polyline.appendShape(this.getSegmentAt(k));
            }
            ret.push(new_polyline);
        }
        return ret;
    }
    getTrimEnd(trimPoint, clickPoint) {
        //
        var index = this.getClosestSegment(trimPoint);
        if (index == 0) {
            if (this.countSegments() > 1) {
                return false;
            }
            var temp_line = new RLine(this._vertices[0], this._vertices[1]);
            return temp_line.getTrimEnd(trimPoint, clickPoint);
        }
        return true;
    }
    //裁剪头，留尾;
    trimStartPoint(trimPoint, clickPoint, extend) {
        var tp = this.getClosesetSegment(trimPoint);
        if (tp == -1) {
            return false;
        }
        //取后面的点坐标;
        if (extend == false) {
            let clone_points = [];
            clone_points.push(trimPoint);
            for (let i = tp + 1; i < this._vertices.length; ++i) {
                clone_points.push(this._vertices[i]);
            }
            this._vertices = clone_points;
        }
        else {
            this._vertices[this._vertices.length - 1] = trimPoint;
        }
        return true;
    }
    //裁剪和延申共用一个方法，裁剪尾就好留头;
    trimEndPoint(trimPoint, clickPoint, extend) {
        var tp = this.getClosesetSegment(trimPoint);
        if (tp == -1) {
            return false;
        }
        let clone_points = [];
        for (let i = 0; i <= tp; ++i) {
            clone_points.push(this._vertices[i]);
        }
        clone_points.push(trimPoint);
        this._vertices = clone_points;
        return true;
    }
    countSegments() {
        var ret = this.countVertices();
        if (!this.isClosed()) {
            ret -= 1;
        }
        if (ret < 0) {
            ret = 0;
        }
        return ret;
    }
    getSegmentAt(index) {
        var p1 = this._vertices[index];
        var count = this._vertices.length;
        var p2 = this._vertices[(index + 1) % count];
        if (this.isStraight(this._bulges[index])) {
            return new RLine(p1, p2);
        }
        let bulge = this._bulges[index];
        let reversed = bulge < 0.0;
        let alpha = Math.atan(bulge) * 4.0;
        if (Math.abs(alpha) > 2 * Math.PI - ltutils_1.PointTolerance) {
            return new RLine(p1, p2);
        }
        let radius;
        let center = new ltpoint_1.LTPoint(0, 0);
        let middle;
        let dist = 0;
        let angle = 0;
        middle = new ltpoint_1.LTPoint((p1.x + p2.x) / 2.0, (p1.y + p2.y) / 2);
        dist = p1.getDistanceTo(p2) / 2.0;
        angle = p1.getAngleTo(p2);
        // alpha can't be 0.0 at this point
        radius = Math.abs(dist / Math.sin(alpha / 2.0));
        let rootTerm = Math.abs(radius * radius - dist * dist);
        let h = Math.sqrt(rootTerm);
        if (bulge > 0.0) {
            angle += Math.PI / 2.0;
        }
        else {
            angle -= Math.PI / 2.0;
        }
        if (Math.abs(alpha) > Math.PI) {
            h *= -1.0;
        }
        center.setPolar(h, angle);
        center = center.plus(middle);
        let a1 = center.getAngleTo(p1);
        let a2 = center.getAngleTo(p2);
        return new RArc(center, radius, a1, a2, reversed);
    }
    getLengthTo(p, limited = true) {
        let ret = 0.0;
        if (p.equalsFuzzy(this.getStartPoint())) {
            return 0.0;
        }
        let segIdx = this.getClosestSegment(p);
        if (segIdx < 0) {
            return -1.0;
        }
        for (let i = 0; i < segIdx; i++) {
            let l = this.getSegmentAt(i).getLength();
            ret += l;
        }
        let seg = this.getSegmentAt(segIdx);
        let lim = limited;
        if (segIdx != 0 && segIdx != this.countSegments() - 1) {
            lim = true;
        }
        let p2 = seg.getClosestPointOnShape(p, lim);
        if (p2 == null) {
            return ret;
        }
        seg.trimEndPoint(p2);
        ret += seg.getLength();
        return ret;
    }
    getLength() {
        let ret = 0.0;
        let sub = this.getExploded();
        for (let i = 0; i < sub.length; ++i) {
            ret += sub[i].getLength();
        }
        return ret;
    }
    getEndPoints() {
        return this._vertices;
    }
    getVectorTo(point, limited, strictRange = RNANDOUBLE) {
        var sub = this.getExploded();
        var ret = null;
        for (var i = 0; i < sub.length; ++i) {
            var shape = sub[i];
            var lim = limited;
            if (i != 0 && i != sub.length - 1) {
                lim = true;
            }
            var v = shape.getVectorTo(point, lim, strictRange);
            if (v != null && (ret == null || v.getMagnitude() < ret.getMagnitude())) {
                ret = v;
            }
        }
        return ret;
    }
    getShapes(queryBox, ignoreComplex = true, issegment = false) {
        if (!ignoreComplex) {
            return [];
        }
        var ret = [];
        var candidates = this.getExploded();
        if (queryBox == null) {
            return candidates;
        }
        for (var i = 0; i < candidates.length; ++i) {
            if (candidates[i].getBoundingBox().intersects(queryBox)) {
                ret.push(candidates[i]);
            }
        }
        return ret;
    }
    //判断凸度是否为0,目前默认为0;
    isStraight(bulge) {
        return Math.abs(bulge) < 1.0e-6;
    }
    //打散成一段段;
    getExploded() {
        if (this._vertices.length <= 1) {
            return [];
        }
        var ret = [];
        var count = this._vertices.length;
        for (var i = 0; i < count; i++) {
            if (!closed && i == count - 1) {
                break;
            }
            var subShape = this.getSegmentAt(i);
            if (subShape.getLength() == 0) {
                continue;
            }
            ret.push(subShape);
        }
        return ret;
    }
    //处理宽度问题;
    getShapes2() {
        if (this._vertices.length <= 1) {
            return [];
        }
        let ret = [];
        const getshape = (index) => {
            var p1 = this._vertices[index];
            var count = this._vertices.length;
            var p2 = this._vertices[(index + 1) % count];
            if (this.isStraight(this._bulges[index])) {
                if (this.startWidths[index] > 0 || this.endWidths[index] > 0) {
                    let trians = this.triangulateLine(index);
                    ret.push(...trians);
                }
                else {
                    ret.push(new RLine(p1, p2));
                }
            }
            let bulge = this._bulges[index];
            let reversed = bulge < 0.0;
            let alpha = Math.atan(bulge) * 4.0;
            if (Math.abs(alpha) > 2 * Math.PI - ltutils_1.PointTolerance) {
                return new RLine(p1, p2);
            }
            let radius;
            let center = new ltpoint_1.LTPoint(0, 0);
            let middle;
            let dist = 0;
            let angle = 0;
            middle = new ltpoint_1.LTPoint((p1.x + p2.x) / 2.0, (p1.y + p2.y) / 2);
            dist = p1.getDistanceTo(p2) / 2.0;
            angle = p1.getAngleTo(p2);
            // alpha can't be 0.0 at this point
            radius = Math.abs(dist / Math.sin(alpha / 2.0));
            let rootTerm = Math.abs(radius * radius - dist * dist);
            let h = Math.sqrt(rootTerm);
            if (bulge > 0.0) {
                angle += Math.PI / 2.0;
            }
            else {
                angle -= Math.PI / 2.0;
            }
            if (Math.abs(alpha) > Math.PI) {
                h *= -1.0;
            }
            center.setPolar(h, angle);
            center = center.plus(middle);
            let a1 = center.getAngleTo(p1);
            let a2 = center.getAngleTo(p2);
            let shape = new RArc(center, radius, a1, a2, reversed);
            ret.push(shape);
        };
        var count = this._vertices.length;
        for (var i = 0; i < count; i++) {
            if (!closed && i == count - 1) {
                break;
            }
            getshape(i);
        }
        return ret;
    }
    get vertices() {
        return this._vertices;
    }
    set vertices(v) {
        this._vertices = v;
    }
    //获取最近的segment的索引;
    getClosesetSegment(point, start_index = 0) {
        var ret = -1;
        var minDist = -1;
        for (let i = start_index; i < this.countSegments(); ++i) {
            var segment = this.getSegmentAt(i);
            if (segment == null) {
                break;
            }
            var dis = segment.getDistanceTo(point, true);
            if (minDist < 0 || dis < minDist) {
                minDist = dis;
                ret = i;
            }
        }
        return ret;
    }
    rotate(rotation, center) {
        for (var i = 0; i < this._vertices.length; i++) {
            this._vertices[i].rotate(rotation, center);
        }
    }
    mirror(axis) {
        for (var i = 0; i < this._vertices.length; i++) {
            this._vertices[i].mirror(axis);
        }
        // for (i=0; i<bulges.size(); i++) {
        //     bulges[i] *= -1;
        // }
        return true;
    }
    _getSaveJson() {
        let dict = {};
        let pointsdict = [];
        for (var i = 0; i < this._vertices.length; i++) {
            let js = { x: this._vertices[i].x, y: this._vertices[i].y, bulges: this._bulges[i],
                startwidth: this._start_widths[i], endwidth: this._end_widths[i] };
            pointsdict[i] = js;
        }
        if (this.getEntityName() == "AcDbWipeout") {
            let json = { close: this.isClosed(), points: pointsdict, entitytype: this.getEntityName() };
            let array_list = [];
            array_list.push(json);
            return array_list;
        }
        else {
            let json = { close: this.isClosed(), points: pointsdict, entitytype: this.getEntityName() };
            return json;
        }
    }
    getSaveValue() {
        let dict = [];
        let json = this._getSaveJson();
        let str = JSON.stringify(json);
        dict[ltfields_1.FieldNames.Cadgeo] = str;
        return dict;
    }
    getBoundingBox() {
        let shapes = this.getExploded();
        let bbox = ltpoint_1.RBox.nullBox();
        if (shapes == null) {
            return bbox;
        }
        for (let i = 0; i < shapes.length; i++) {
            bbox.growToInclude(shapes[i].getBoundingBox());
        }
        return bbox;
    }
    moveVertexAt(index, offset) {
        if (index < 0 || index >= this._vertices.length) {
            return;
        }
        this._vertices[index].x += offset.x;
        this._vertices[index].y += offset.y;
    }
    moveSegmentAt(index, offset) {
        this.moveVertexAt(index, offset);
        //面移动
        if (this._isPolygion && index == 0) {
            this.moveVertexAt(this._vertices.length - 1, offset);
        }
        else if (this._isPolygion && index + 1 == this._vertices.length - 1) {
            this.moveVertexAt(0, offset);
        }
        if (index + 1 < this._vertices.length) {
            this.moveVertexAt(index + 1, offset);
        }
        else {
            if (this.isClosed()) {
                this.moveVertexAt(0, offset);
            }
        }
    }
    moveReferencePoint(referencePoint, targetPoint) {
        for (let i = 0; i < this._vertices.length; ++i) {
            if (this._vertices[i].equalsFuzzy(referencePoint) == true) {
                this._vertices[i] = targetPoint;
            }
        }
        let shapes = this.getExploded();
        for (let j = 0; j < shapes.length; j++) {
            let mid = shapes[j].getMiddlePoint();
            if (mid.equalsFuzzy(referencePoint) == true) {
                this.moveSegmentAt(j, targetPoint.minus(referencePoint));
            }
        }
        //中间点
        return true;
    }
    getReferencePoints() {
        let ret = [];
        for (let i = 0; i < this.vertices.length; ++i) {
            ret.push(this._vertices[i].clone());
            if (i < this.vertices.length - 1) {
                let line = this.getSegmentAt(i);
                let mid_point = line.getMiddlePoint().clone();
                mid_point.flag = ltpoint_1.Flag.Secondary;
                ret.push(mid_point);
            }
        }
        return ret;
    }
    move(offset) {
        for (let i = 0; i < this._vertices.length; ++i) {
            this._vertices[i] = this._vertices[i].plus(offset);
        }
    }
    scale(scale_factors, c = null) {
        for (let i = 0; i < this._vertices.length; ++i) {
            this._vertices[i].scale(scale_factors.x, scale_factors.y, c);
        }
    }
    intersectsWith(other, limited) {
        let shapes = this.getShapes(null);
        for (let i = 0; i < shapes.length; i++) {
            if (shapes[i] == null) {
                continue;
            }
            //造成死循环;
            if (shapes[i].intersectsWith(other, limited)) {
                return true;
            }
        }
        return false;
    }
}
exports.RPolyline = RPolyline;
class RPoint extends Shape {
    constructor(point) {
        super();
        this.position = point;
        this._rotate = 0;
        this.xscale = 1;
        this.yscale = 1;
    }
    setPoint(point) {
        this.position = point;
    }
    scale(scale_factors, c = null) {
        this.position.scale(scale_factors.x, scale_factors.y, c);
        this.xscale = scale_factors.x * this.xscale;
        this.yscale = scale_factors.y * this.yscale;
    }
    getExploded() {
        let ret1 = [];
        ret1.push(this);
        return ret1;
    }
    setScale(x, y) {
        this.xscale = x;
        this.yscale = y;
    }
    getDirection1() {
        return 0;
    }
    getDirection2() {
        return 0;
    }
    getSideOfPoint(point) {
        return Side.NoSide;
    }
    getShapeType() {
        return ltutils_1.ShapeType.Point;
    }
    getShapeName() {
        return "点";
    }
    getEntityName() {
        return "AcdbPoint";
    }
    rotate(rotation, center) {
        if (Math.abs(rotation) < 1.0e-9) {
            return false;
        }
        this.position.rotate(rotation, center);
        this.setRotate(ltmath_js_1.LTMath.getNormalizedAngle(this.getRotate() + rotation));
        return true;
    }
    mirror(axis) {
        this.position.mirror(axis);
    }
    clone() {
        let point = new RPoint(new ltpoint_1.LTPoint(this.position.x, this.position.y));
        point.xscale = this.xscale;
        point.yscale = this.yscale;
        return point;
    }
    getBoundingBox() {
        return ltpoint_1.RBox.formVectors(this.position, this.position);
    }
    getLength() {
        return 0.0;
    }
    getEndPoints() {
        var ret = [];
        ret.push(this.position);
        return ret;
    }
    getEndPoint() {
        return this.position || new ltpoint_1.LTPoint(0, 0);
    }
    getMiddlePoints() {
        return this.getEndPoints();
    }
    getMiddlePoint() {
        return this.position;
    }
    getCenterPoints() {
        return this.getEndPoints();
    }
    getPointsWithDistanceToEnd(distance, from) {
        return [];
    }
    getAngleAt(distance, from) {
        return RNANDOUBLE;
    }
    getVectorTo(point, limited, strictRange) {
        return point.minus(this.position);
    }
    getShapes(queryBox, ignoreComplex = true, issegment) {
        return [];
    }
    getClosestPointOnEntity() {
        return null;
    }
    getReferencePoints() {
        let ret = [];
        ret.push(this.position);
        return ret;
    }
    moveReferencePoint(referencePoint, targetPoint) {
        let ret = false;
        if (referencePoint.equalsFuzzy(this.position) == true) {
            this.position = targetPoint;
            ret = true;
        }
        return ret;
    }
    move(offset) {
        this.position.move(offset.x, offset.y);
    }
}
exports.RPoint = RPoint;
var TextHorzMode;
(function (TextHorzMode) {
    TextHorzMode[TextHorzMode["kTextLeft"] = 0] = "kTextLeft";
    TextHorzMode[TextHorzMode["kTextCenter"] = 1] = "kTextCenter";
    TextHorzMode[TextHorzMode["kTextRight"] = 2] = "kTextRight";
    TextHorzMode[TextHorzMode["kTextAlign"] = 3] = "kTextAlign";
    TextHorzMode[TextHorzMode["kTextMid"] = 4] = "kTextMid";
    TextHorzMode[TextHorzMode["kTextFit"] = 5] = "kTextFit";
})(TextHorzMode || (exports.TextHorzMode = TextHorzMode = {}));
var TextVertMode;
(function (TextVertMode) {
    TextVertMode[TextVertMode["kTextBase"] = 0] = "kTextBase";
    TextVertMode[TextVertMode["kTextBottom"] = 1] = "kTextBottom";
    TextVertMode[TextVertMode["kTextVertMid"] = 2] = "kTextVertMid";
    TextVertMode[TextVertMode["kTextTop"] = 3] = "kTextTop";
})(TextVertMode || (exports.TextVertMode = TextVertMode = {}));
class RText extends Shape {
    constructor(pos, text, textheight) {
        super();
        this._rbox = null;
        this._width = 0;
        this._height = 0;
        this._textheight = 1;
        this._scale = 1.0;
        this._color = ltentity_1.dwg_bylayer;
        this._bbox_vertices = new ltpoint_1.LTPoints(undefined);
        this._info = null;
        this._vMode = TextVertMode.kTextBase;
        this._hMode = TextHorzMode.kTextLeft;
        this._fontname = "Standard";
        this._mtext = false;
        this._expression = false;
        this._fitpos = null;
        //宽度因子
        this.width_factor = 1;
        this.position = pos;
        this._text = text;
        this._textheight = textheight;
        this._dirty = true;
    }
    get boxheight() {
        if (this._height == 0 || this._dirty == true) {
            this.initWH();
        }
        return this._height;
    }
    get textVertMode() {
        return this._vMode;
    }
    get fontName() {
        return this._fontname;
    }
    set fontName(name) {
        this._fontname = name;
    }
    get widthFactor() {
        return this.width_factor;
    }
    set widthFactor(wf) {
        this.width_factor = wf;
        this._dirty = true;
    }
    set textVertMode(vmode) {
        this._vMode = vmode;
    }
    get textHorzMode() {
        return this._hMode;
    }
    set textHorzMode(hmode) {
        this._hMode = hmode;
    }
    getShapeName() {
        return "文本";
    }
    getEntityName() {
        return "AcDbText";
    }
    getDirection1() {
        return 0;
    }
    getDirection2() {
        return 0;
    }
    getSideOfPoint(point) {
        return Side.NoSide;
    }
    getVectorTo(point, limited, strictRange) {
        return null;
    }
    getDistanceTo(point, limited = true, strictRange = RNANDOUBLE) {
        let box = this.getBoundingBox();
        if (box.contain(point)) {
            return 0.0;
        }
        let line = ltutils_1.LTUtils.boxToPolyline(box);
        return line.data.getDistanceTo(point, limited, strictRange);
    }
    getEndPoint() {
        return this.position || new ltpoint_1.LTPoint(0, 0);
    }
    setPosition(pos) {
        this.position = pos;
    }
    getPointsWithDistanceToEnd(distance, from = From.FromAny) {
        return [];
    }
    get width() {
        if (this._width == 0 || this._dirty == true) {
            this.initWH();
        }
        return this._width;
    }
    set width(w) {
        this._width = w;
        this._dirty = true;
    }
    get height() {
        return this._textheight;
    }
    set height(th) {
        this._textheight = Number(th);
        this._dirty = true;
    }
    get color() {
        return this._color;
    }
    set color(c) {
        this._color = c;
    }
    getScale() {
        return this._scale;
    }
    setScale(scale) {
        this._scale = scale;
    }
    getLength() {
        return 0;
    }
    get text() {
        return this._text;
    }
    set text(tx) {
        this._text = tx;
    }
    getExploded() {
        return [];
    }
    getRefWidth() {
        let ref_width = 0;
        if (this._fitpos !== null) {
            ref_width = Math.abs(this.position.getDistanceTo(this._fitpos));
        }
        return ref_width;
    }
    initWH() {
        let wh = ltcanvas_1.TsPixiCanvas.getTextWH(this.text, this.fontName, this.height, this.width_factor, this.getRefWidth());
        if (this.position === null) {
            return;
        }
        //外扩一点增加容错误
        this._height = wh.height;
        this._width = wh.width;
        let dis = wh.width * 0.08;
        let half_width = wh.width / 2.0 + dis;
        let half_height = wh.height / 2.0;
        let left_b_x = this.position.x - half_width;
        let left_b_y = this.position.y - half_height;
        let right_t_x = this.position.x + half_width;
        let right_t_y = this.position.y + half_height;
        this._bbox_vertices.clear();
        this._bbox_vertices.addPoint(new ltpoint_1.LTPoint(left_b_x, left_b_y));
        this._bbox_vertices.addPoint(new ltpoint_1.LTPoint(right_t_x, left_b_y));
        this._bbox_vertices.addPoint(new ltpoint_1.LTPoint(right_t_x, right_t_y));
        this._bbox_vertices.addPoint(new ltpoint_1.LTPoint(left_b_x, right_t_y));
        this._bbox_vertices.addPoint(new ltpoint_1.LTPoint(left_b_x, left_b_y));
        let offsetx = 0;
        let offsety = 0;
        // //居中的
        if (this._hMode == TextHorzMode.kTextLeft) {
            offsetx = half_width;
        }
        else if (this._hMode == TextHorzMode.kTextCenter) {
            offsetx = dis;
        }
        else if (this._hMode == TextHorzMode.kTextRight) {
            offsetx = -half_width + dis;
        }
        else if (this._hMode == TextHorzMode.kTextAlign) {
            offsetx = -half_width + dis;
        }
        if (this._vMode == TextVertMode.kTextBase) {
            offsety = half_height + wh.des;
        }
        else if (this._vMode == TextVertMode.kTextBottom) {
            offsety = half_height;
        }
        else if (this._vMode == TextVertMode.kTextVertMid) {
            offsety = 0;
        }
        else if (this._vMode == TextVertMode.kTextTop) {
            offsety = -half_height;
        }
        //特殊
        if (this._hMode == TextHorzMode.kTextMid) {
            offsetx = 0;
            offsety = 0;
        }
        else if (this._hMode == TextHorzMode.kTextFit) {
            offsetx = half_width;
            offsety = half_height + wh.des;
        }
        //平移
        // offsetx = half_width;
        // offsety = half_height +wh.des;
        ltpoint_1.LTPoint.moveList(this._bbox_vertices._points, new ltpoint_1.LTPoint(offsetx, offsety));
        //旋转
        ltpoint_1.LTPoint.rotateListByPoint(this._bbox_vertices._points, this._rotate, this.position);
    }
    mirror(axis) {
        var _a;
        (_a = this.position) === null || _a === void 0 ? void 0 : _a.mirror(axis);
        if (this._fitpos !== null) {
            this._fitpos.mirror(axis);
        }
        //let readable = LTMath.isAngleReadable(this._rotate);
        let vec = ltpoint_1.LTPoint.createPolar(1.0, this._rotate);
        vec.mirror(new RLine(new ltpoint_1.LTPoint(0.0, 0.0), axis.getEndPoint().minus(axis.getStartPoint())));
        this._rotate = vec.getAngle();
    }
    setRotate(rotate) {
        if (isNaN(rotate)) {
            return;
        }
        this._dirty = true;
        this._rotate = rotate * 1;
    }
    clone() {
        let pos = this.position || new ltpoint_1.LTPoint(0, 0);
        let t = new RText(new ltpoint_1.LTPoint(pos.x * 1, pos.y * 1), this._text, this._textheight);
        t.width = this.width;
        t.setRotate(this.getRotate());
        t._scale = this._scale;
        t._vMode = this._vMode;
        t._hMode = this._hMode;
        if (this._fitpos !== null) {
            t._fitpos = this._fitpos.clone();
        }
        t._expression = this._expression;
        t._fontname = this._fontname;
        t.width_factor = this.width_factor;
        t._color = this._color;
        return t;
    }
    move(offset) {
        if (this.position === null) {
            return;
        }
        this.position.move(offset.x, offset.y);
        if (this._fitpos !== null) {
            this._fitpos.move(offset.x, offset.y);
        }
        this._dirty = true;
    }
    rotate(rotation, center) {
        if (this.position === null) {
            return;
        }
        this.position.rotate(rotation, center);
        if (this._fitpos !== null) {
            this._fitpos.rotate(rotation, center);
        }
        this.setRotate(ltmath_js_1.LTMath.getNormalizedAngle(this.getRotate() + rotation));
        this._dirty = true;
    }
    scale(factors, center) {
        if (this.position !== null) {
            this.position.scale(factors.x, factors.y, center);
        }
        if (this._fitpos !== null) {
            this._fitpos.scale(factors.x, factors.y, center);
        }
        this._textheight = this._textheight * factors.x;
        this._dirty = true;
    }
    moveReferencePoint(referencePoint, targetPoint) {
        let ret = false;
        let pos = this.position || new ltpoint_1.LTPoint(0, 0);
        if (referencePoint.equalsFuzzy(pos) == true) {
            if (this._fitpos !== null) {
                let offset = pos.minus(targetPoint);
                this._fitpos = this._fitpos.minus(offset);
            }
            this.position = targetPoint;
            ret = true;
        }
        this._dirty = true;
        return ret;
    }
    getShapeType() {
        return ltutils_1.ShapeType.Text;
    }
    getBoundingBox() {
        if (this._dirty == true || this._rbox == null) {
            {
                this.initWH();
            }
            this._rbox = this._bbox_vertices.getRBox();
            this._dirty = false;
        }
        return this._rbox;
    }
    contains(pos) {
        let is_contain = false;
        if (ltutils_1.LTUtils.containsByPoint(this._bbox_vertices._points, pos) == true) {
            return true;
        }
        return is_contain;
    }
    intersectsWith(other, limited) {
        this.getBoundingBox();
        let rline = new RPolyline(this._bbox_vertices._points);
        return rline.intersectsWith(other, limited);
    }
    getShapes(queryBox, ignoreComplex = true, issegment) {
        return [];
    }
    getClosestPointOnEntity() {
        return null;
    }
    getReferencePoints() {
        let ret = [];
        let pos = this.position || new ltpoint_1.LTPoint(0, 0);
        ret.push(pos);
        return ret;
    }
    set FontName(name) {
        this._fontname = name;
    }
    setFontInfo(info) {
        let json_value = JSON.parse(info);
        this._info = info;
        this._mtext = json_value.mtext;
        this._expression = json_value.expression;
        this._vMode = json_value.vmode;
        this._hMode = json_value.hmode;
        this._fontname = json_value.fontname;
        this.width_factor = json_value.width_factor;
        if (json_value.fitpos !== undefined && json_value.fitpos !== null) {
            this._fitpos = new ltpoint_1.LTPoint(Number(json_value.fitpos.x), Number(json_value.fitpos.y));
        }
    }
    getSaveValue() {
        let dict = [];
        dict[ltfields_1.FieldNames.Text] = this.text;
        // if (this._info != null) {
        //   dict[FieldNames.Info] = this._info;
        // }
        // else {
        let info = {
            mtext: false, fontname: this._fontname, expression: this._expression,
            vmode: this._vMode, hmode: this._hMode, fitpos: undefined, width_factor: this.width_factor
        };
        if (this._fitpos !== null) {
            info.fitpos = this._fitpos.toJson();
        }
        dict[ltfields_1.FieldNames.Info] = JSON.stringify(info);
        //}
        dict[ltfields_1.FieldNames.Width] = this._textheight;
        dict[ltfields_1.FieldNames.Rotate] = this._rotate;
        if (this.position !== null) {
            let poss = [];
            poss.push(this.position);
            let str = ltpoint_1.LTPoints.toJson(poss);
            let pjson = {};
            pjson["points"] = str;
            dict[ltfields_1.FieldNames.Cadgeo] = JSON.stringify(pjson);
        }
        return dict;
    }
}
exports.RText = RText;
class RCircle extends Shape {
    constructor(center, radius) {
        super();
        this._center = center;
        this._radius = radius;
    }
    getPointsWithDistanceToEnd(distance, from = From.FromAny) {
        return [];
    }
    getShapeType() {
        return ltutils_1.ShapeType.Circle;
    }
    static createFrom2Points(p1, p2) {
        var center = p1.plus(p2).dot(0.5);
        var radius = p1.getDistanceTo(p2) / 2.0;
        return new RCircle(center, radius);
    }
    static createFrom3Points(p1, p2, p3) {
        let mp1 = ltpoint_1.LTPoint.getAverage(p1, p2);
        let a1 = p1.getAngleTo(p2) + Math.PI / 2.0;
        // direction from middle point to center:
        let dir1 = ltpoint_1.LTPoint.createPolar(1.0, a1);
        // middle points between last two points:
        let mp2 = ltpoint_1.LTPoint.getAverage(p2, p3);
        let a2 = p2.getAngleTo(p3) + Math.PI / 2.0;
        // direction from middle point to center:
        let dir2 = ltpoint_1.LTPoint.createPolar(1.0, a2);
        let midLine1 = new RLine(mp1, mp1.plus(dir1));
        let midLine2 = new RLine(mp2, mp2.plus(dir2));
        let ips = midLine1.getIntersectionPoints(midLine2, false);
        if (ips.length != 1) {
            return null;
        }
        let center = ips[0];
        let radius = center.getDistanceTo(p3);
        return new RCircle(center, radius);
    }
    getShapeName() {
        return "圆";
    }
    getEntityName() {
        return "AcDbCircle";
    }
    clone() {
        let ret = new RCircle(this._center.clone(), this._radius);
        return ret;
    }
    getExploded() {
        return [];
    }
    getDirection1() {
        return 0;
    }
    getDirection2() {
        return 0;
    }
    getSideOfPoint(point) {
        return Side.NoSide;
    }
    getHull() {
        let line = this.toArc(0).approximateWithLines(0, 5 * Math.PI / 180.0);
        return (0, converthull_1.convertHull)(line.subData().vertices);
    }
    toArc(startAngle) {
        return new RArc(this.getCenter(), this.getRadius(), startAngle, startAngle + 2 * Math.PI, false);
    }
    getCenter() {
        return this._center;
    }
    getRadius() {
        return this._radius;
    }
    getBoundingBox() {
        var p1 = this._center.minus(new ltpoint_1.LTPoint(this._radius, this._radius));
        var p2 = this._center.plus(new ltpoint_1.LTPoint(this._radius, this._radius));
        return ltpoint_1.RBox.formVectors(p1, p2);
    }
    getLength() {
        return 2 * this._radius * Math.PI;
    }
    getArea() {
        return this._radius * this._radius * Math.PI;
    }
    contains(p) {
        return p.getDistanceTo(this._center) < this._radius;
    }
    getEndPoints() {
        return [];
    }
    getMiddlePoints() {
        return [];
    }
    getMiddlePoint() {
        return this.position || new ltpoint_1.LTPoint(0, 0);
    }
    getEndPoint() {
        return this.position || new ltpoint_1.LTPoint(0, 0);
    }
    getShapes(queryBox, ignoreComplex, issegment) {
        var ret = [];
        ret.push(new RCircle(this._center, this._radius));
        return ret;
    }
    getReferencePoints() {
        var ret = [];
        ret.push(this._center);
        var p1 = this._center.plus(new ltpoint_1.LTPoint(this._radius, 0));
        p1.flag = ltpoint_1.Flag.NoFlags;
        ret.push(p1);
        var p2 = this._center.plus(new ltpoint_1.LTPoint(0, this._radius));
        p2.flag = ltpoint_1.Flag.NoFlags;
        ret.push(p2);
        var p3 = this._center.minus(new ltpoint_1.LTPoint(this._radius, 0));
        p3.flag = ltpoint_1.Flag.NoFlags;
        ret.push(p3);
        var p4 = this._center.minus(new ltpoint_1.LTPoint(0, this._radius));
        p4.flag = ltpoint_1.Flag.NoFlags;
        ret.push(p4);
        return ret;
    }
    setRadius(radius) {
        this._radius = radius;
    }
    setCenterPoint(center) {
        this._center = center;
    }
    getCenterPoints() {
        var ret = [];
        ret.push(this._center);
        return ret;
    }
    getAngleAt(distance, from) {
        return RNANDOUBLE;
    }
    getVectorTo(point, limited, strictRange) {
        var v = point.minus(this._center);
        if (v.getMagnitude() < 1e-9) {
            return null;
        }
        return ltpoint_1.LTPoint.createPolar(v.getMagnitude() - this._radius, v.getAngle());
    }
    move(offset) {
        this._center = this._center.plus(offset);
    }
    rotate(rotation, c) {
        this._center.rotate(rotation, c);
    }
    scale(scale_factors, c = null) {
        this._center.scale(scale_factors.x, scale_factors.y, c);
        this._radius *= scale_factors.x;
        if (this._radius < 0) {
            this._radius *= -1;
        }
        return true;
    }
    mirror(axis) {
        this._center.mirror(axis);
        return true;
    }
    moveReferencePoint(referencePoint, targetPoint) {
        var ret = false;
        if (referencePoint.equalsFuzzy(this._center)) {
            this._center = targetPoint;
            ret = true;
        }
        else if (referencePoint.equalsFuzzy(this._center.plus(new ltpoint_1.LTPoint(this._radius, 0))) ||
            referencePoint.equalsFuzzy(this._center.plus(new ltpoint_1.LTPoint(0, this._radius))) ||
            referencePoint.equalsFuzzy(this._center.minus(new ltpoint_1.LTPoint(this._radius, 0))) ||
            referencePoint.equalsFuzzy(this._center.minus(new ltpoint_1.LTPoint(0, this._radius)))) {
            this._radius = this._center.getDistanceTo(targetPoint);
            ret = true;
        }
        return ret;
    }
    _getSaveJson() {
        const json = { "radius": this._radius, entitytype: "acdbcircle", "center": { "x": this._center.x, "y": this._center.y } };
        return json;
    }
    getSaveValue() {
        let dict = [];
        let json = this._getSaveJson();
        let str = JSON.stringify(json);
        dict[ltfields_1.FieldNames.Cadgeo] = str;
        return dict;
    }
    splitAt(points) {
        if (points.length == 0) {
            return [];
        }
        let ret = [];
        let refAngle = this._center.getAngleTo(points[0]);
        let startPoint;
        let endPoint;
        startPoint = endPoint = this._center.plus(ltpoint_1.LTPoint.createPolar(this._radius, refAngle));
        let sortedPoints = ltpoint_1.LTPoint.getSortedByAngle(points, this._center, refAngle);
        if (!startPoint.equalsFuzzy(sortedPoints[0])) {
            sortedPoints.unshift(startPoint);
        }
        if (!endPoint.equalsFuzzy(sortedPoints[sortedPoints.length - 1])) {
            sortedPoints.push(endPoint);
        }
        for (let i = 0; i < sortedPoints.length - 1; i++) {
            if (sortedPoints[i].equalsFuzzy(sortedPoints[i + 1])) {
                continue;
            }
            ret.push(new RArc(this._center.clone(), this._radius, this._center.getAngleTo(sortedPoints[i]), this._center.getAngleTo(sortedPoints[i + 1]), false));
        }
        return ret;
    }
}
exports.RCircle = RCircle;
class RArc extends Shape {
    constructor(center, radius, start_angle, end_angle, reversed = false) {
        super();
        this._uAxisx = 1;
        this._uAxisy = 1;
        this._center = center;
        this._radius = radius;
        this._start_angle = start_angle;
        this._end_angle = end_angle;
        this._reversed = reversed;
    }
    get center() {
        return this._center;
    }
    set center(c) {
        this._center = c;
    }
    set radius(rad) {
        this._radius = rad;
    }
    getShapeType() {
        return ltutils_1.ShapeType.Arc;
    }
    getShapeName() {
        return "圆弧";
    }
    getEntityName() {
        return "AcDbArc";
    }
    getSideOfPoint(point) {
        if (this._reversed) {
            if (this._center.getDistanceTo(point) < this._radius) {
                return Side.RightHand;
            }
            else {
                return Side.LeftHand;
            }
        }
        else {
            if (this._center.getDistanceTo(point) < this._radius) {
                return Side.LeftHand;
            }
            else {
                return Side.RightHand;
            }
        }
    }
    getVectorTo(point, limited, strictRange) {
        let angle = this._center.getAngleTo(point);
        if (limited && !ltmath_js_1.LTMath.isAngleBetween(angle, this._start_angle, this._end_angle, this._reversed)) {
            return null;
        }
        let v = point.minus(this._center);
        return ltpoint_1.LTPoint.createPolar(v.getMagnitude() - this._radius, v.getAngle());
    }
    getDistanceFromStart(p) {
        let a1 = this.getStartAngle();
        let ap = this._center.getAngleTo(p);
        if (this._reversed) {
            return ltmath_js_1.LTMath.getAngleDifference(ap, a1) * this._radius;
        }
        else {
            return ltmath_js_1.LTMath.getAngleDifference(a1, ap) * this._radius;
        }
    }
    getExploded() {
        return [];
    }
    getAngleLength() {
        let ret = Math.abs(this.getSweep());
        // full circle or zero length arc:
        if (ret < ltutils_1.PointTolerance) {
            ret = 2 * Math.PI;
        }
        return ret;
    }
    getSaveValue() {
        let dict = [];
        const json = {
            endangle: this._end_angle, radius: this._radius, startangle: this._start_angle,
            center: { x: this._center.x, y: this._center.y }, reversed: this._reversed, uAxisx: this._uAxisx,
            uAxisy: this._uAxisy
        };
        let str = JSON.stringify(json);
        dict[ltfields_1.FieldNames.Cadgeo] = str;
        return dict;
    }
    splitAt(points) {
        let ret = [];
        if (this._reversed) {
            let arc = this.clone();
            arc.reverse();
            ret = arc.splitAt(points);
            return Shape.getReversedShapeList(ret);
        }
        let startPoint = this.getStartPoint();
        let endPoint = this.getEndPoint();
        let sortedPoints = ltpoint_1.LTPoint.getSortedByAngle(points, this._center, this.getStartAngle());
        if (!startPoint.equalsFuzzy(sortedPoints[0])) {
            sortedPoints.unshift(startPoint);
        }
        if (!endPoint.equalsFuzzy(sortedPoints[sortedPoints.length - 1])) {
            sortedPoints.push(endPoint);
        }
        for (let i = 0; i < sortedPoints.length - 1; i++) {
            if (sortedPoints[i].equalsFuzzy(sortedPoints[i + 1])) {
                continue;
            }
            let seg = this.clone();
            let a1 = this._center.getAngleTo(sortedPoints[i]);
            let a2 = this._center.getAngleTo(sortedPoints[i + 1]);
            if (Math.abs(ltmath_js_1.LTMath.getAngleDifference180(a1, a2) * this._radius) < 0.001) {
                continue;
            }
            seg._start_angle = a1;
            seg._end_angle = a2;
            ret.push(seg);
        }
        return ret;
    }
    getLength() {
        return Math.abs(this.getAngleLength()) * this._radius;
    }
    isDirected() {
        return true;
    }
    static createFrom3Points(start_point, center_point, end_point) {
        let mp1 = ltpoint_1.LTPoint.getAverage(start_point, center_point);
        let a1 = start_point.getAngleTo(center_point) + Math.PI / 2.0;
        let dir1 = ltpoint_1.LTPoint.createPolar(1.0, a1);
        let mp2 = ltpoint_1.LTPoint.getAverage(center_point, end_point);
        let a2 = center_point.getAngleTo(end_point) + Math.PI / 2.0;
        let dir2 = ltpoint_1.LTPoint.createPolar(1.0, a2);
        let midLine1 = new RLine(mp1, mp1.plus(dir1));
        let midLine2 = new RLine(mp2, mp2.plus(dir2));
        let ips = midLine1.getIntersectionPoints(midLine2, false);
        if (ips.length != 1) {
            return null;
        }
        let center = ips[0];
        let radius = center.getDistanceTo(end_point);
        let angle1 = center.getAngleTo(start_point);
        let angle2 = center.getAngleTo(end_point);
        let reversed = ltmath_js_1.LTMath.isAngleBetween(center.getAngleTo(center_point), angle1, angle2, true);
        let result_arc = new RArc(center, radius, angle1, angle2, reversed);
        return result_arc;
    }
    static createFromCenterPos(start_point, center, end_point) {
        var radius = center.getDistanceTo(end_point);
        var angle1 = center.getAngleTo(start_point);
        var angle2 = center.getAngleTo(end_point);
        var reversed = ltmath_js_1.LTMath.isAngleBetween(center.getAngleTo(end_point), angle1, angle2, true);
        return new RArc(center, radius, angle1, angle2, reversed);
    }
    static createFrom2PBulge(startPoint, endPoint, bulge) {
        var reversed = (bulge < 0.0);
        var alpha = Math.atan(bulge) * 4.0;
        var middle = new ltpoint_1.LTPoint((startPoint.x + endPoint.x) / 2.0, (startPoint.y + endPoint.y) / 2.0);
        var dist = startPoint.getDistanceTo(endPoint) / 2.0;
        // alpha can't be 0.0 at this point
        var radius = Math.abs(dist / Math.sin(alpha / 2.0));
        var wu = Math.abs(Math.pow(radius, 2.0) - Math.pow(dist, 2.0));
        var h = Math.sqrt(wu);
        var angle = startPoint.getAngleTo(endPoint);
        if (bulge > 0.0) {
            angle += Math.PI / 2.0;
        }
        else {
            angle -= Math.PI / 2.0;
        }
        if (Math.abs(alpha) > Math.PI) {
            h *= -1.0;
        }
        var center = new ltpoint_1.LTPoint(0, 0);
        center.setPolar(h, angle);
        center = center.plus(middle);
        var startAngle = center.getAngleTo(startPoint);
        var endAngle = center.getAngleTo(endPoint);
        return new RArc(center, radius, startAngle, endAngle, false);
    }
    static createTangential(startPoint, pos, direction, radius) {
        let ortho = ltpoint_1.LTPoint.createPolar(radius, direction + Math.PI / 2.0);
        // two possible center points for arc:
        let center1 = startPoint.plus(ortho);
        let center2 = startPoint.minus(ortho);
        let _center;
        if (center1.getDistanceTo(pos) < center2.getDistanceTo(pos)) {
            _center = center1;
        }
        else {
            _center = center2;
        }
        // angles:
        let startAngle = _center.getAngleTo(startPoint);
        let endAngle = _center.getAngleTo(pos);
        let _reversed = false;
        let arc = new RArc(_center, radius, startAngle, endAngle, _reversed);
        let diff = ltmath_js_1.LTMath.getNormalizedAngle(arc.getDirection1() - direction);
        if (Math.abs(diff - Math.PI) < 1.0e-1) {
            arc.isReversed = true;
        }
        return arc;
    }
    static createTangential1(from_point, x1, y1, x2, y2, radius) {
        const fromX = from_point.x;
        const fromY = from_point.y;
        const a1 = fromY - y1;
        const b1 = fromX - x1;
        const a2 = y2 - y1;
        const b2 = x2 - x1;
        const mm = Math.abs(a1 * b2 - b1 * a2);
        const dd = a1 * a1 + b1 * b1;
        const cc = a2 * a2 + b2 * b2;
        const tt = a1 * a2 + b1 * b2;
        const k1 = radius * Math.sqrt(dd) / mm;
        const k2 = radius * Math.sqrt(cc) / mm;
        const j1 = k1 * tt / dd;
        const j2 = k2 * tt / cc;
        const cx = k1 * b2 + k2 * b1;
        const cy = k1 * a2 + k2 * a1;
        const px = b1 * (k2 + j1);
        const py = a1 * (k2 + j1);
        const qx = b2 * (k1 + j2);
        const qy = a2 * (k1 + j2);
        const startAngle = Math.atan2(py - cy, px - cx);
        const endAngle = Math.atan2(qy - cy, qx - cx);
        let anticlockwise = b1 * a2 > b2 * a1;
        return new RArc(new ltpoint_1.LTPoint(cx, cy), radius, startAngle, endAngle, anticlockwise);
    }
    getStartPoint() {
        return this.getPointAtAngle(this._start_angle);
    }
    getEndPoint() {
        return this.getPointAtAngle(this._end_angle);
    }
    getPointAtAngle(a) {
        return new ltpoint_1.LTPoint(this._center.x + Math.cos(a) * this._radius, this._center.y + Math.sin(a) * this._radius);
    }
    getRadius() {
        return this._radius;
    }
    getBoundingBox() {
        var minV = null;
        var maxV = null;
        var start_point = this.getStartPoint();
        var end_point = this.getEndPoint();
        var minX = Math.min(start_point.x, end_point.x);
        var minY = Math.min(start_point.y, end_point.y);
        var maxX = Math.max(start_point.x, end_point.x);
        var maxY = Math.max(start_point.y, end_point.y);
        if (start_point.getDistanceTo(end_point) < 1.0e-6 &&
            this.getRadius() > 1.0e5) {
            minV = new ltpoint_1.LTPoint(minX, minY);
            maxV = new ltpoint_1.LTPoint(maxX, maxY);
            return ltpoint_1.RBox.formVectors(minV, maxV);
        }
        var a1 = ltmath_js_1.LTMath.getNormalizedAngle(!this._reversed ? this._start_angle : this._end_angle);
        var a2 = ltmath_js_1.LTMath.getNormalizedAngle(!this._reversed ? this._end_angle : this._start_angle);
        // check for left limit:
        if ((a1 < Math.PI && a2 > Math.PI) ||
            (a1 > a2 - 1.0e-12 && a2 > Math.PI) ||
            (a1 > a2 - 1.0e-12 && a1 < Math.PI)) {
            minX = Math.min(this._center.x - this._radius, minX);
        }
        // check for right limit:
        if (a1 > a2 - 1.0e-12) {
            maxX = Math.max(this._center.x + this._radius, maxX);
        }
        const M_PI_2 = Math.PI * 0.5;
        // check for bottom limit:
        if ((a1 < M_PI_2 * 3 && a2 > M_PI_2 * 3) ||
            (a1 > a2 - 1.0e-12 && a2 > M_PI_2 * 3) ||
            (a1 > a2 - 1.0e-12 && a1 < M_PI_2 * 3)) {
            minY = Math.min(this._center.y - this._radius, minY);
        }
        if ((a1 < M_PI_2 && a2 > M_PI_2) ||
            (a1 > a2 - 1.0e-12 && a2 > M_PI_2) ||
            (a1 > a2 - 1.0e-12 && a1 < M_PI_2)) {
            maxY = Math.max(this._center.y + this._radius, maxY);
        }
        minV = new ltpoint_1.LTPoint(minX, minY);
        maxV = new ltpoint_1.LTPoint(maxX, maxY);
        return ltpoint_1.RBox.formVectors(minV, maxV);
    }
    getShapes(queryBox, ignoreComplex, issegment) {
        let v = [];
        v.push(this.clone());
        return v;
    }
    getMiddlePoint() {
        var a = this._start_angle + this.getSweep() / 2.0;
        var v = ltpoint_1.LTPoint.createPolar(this._radius, a);
        v = v.plus(this._center);
        return v;
    }
    getCenter() {
        return this._center;
    }
    getCenterPoints() {
        var ret = [];
        ret.push(this.getCenter());
        return ret;
    }
    getEndPoints() {
        var ret = [];
        ret.push(this.getStartPoint());
        ret.push(this.getEndPoint());
        return ret;
    }
    getMiddlePoints() {
        var ret = [];
        ret.push(this.getMiddlePoint());
        return ret;
    }
    getReferencePoints() {
        let ret = [];
        ret.push(this._center);
        ret.push(this.getStartPoint());
        ret.push(this.getEndPoint());
        ret.push(this.getMiddlePoint());
        // var p: LTPoint[] = [];
        // p.push(this._center.plus(new LTPoint(this._radius, 0)));
        // p.push(this._center.plus(new LTPoint(0, this._radius)));
        // p.push(this._center.minus(new LTPoint(this._radius, 0)));
        // p.push(this._center.minus(new LTPoint(0, this._radius)));
        // for (var i = 0; i < p.length; i++) {
        //   if (LTMath.isAngleBetween(
        //     this._center.getAngleTo(p[i]),
        //     this._start_angle,
        //     this._end_angle,
        //     this._reversed)) {
        //     ret.push(p[i]);
        //   }
        // }
        return ret;
    }
    clone() {
        let rline = new RArc(this._center.clone(), this._radius, this._start_angle, this._end_angle, this._reversed);
        return rline;
    }
    cloneFrom(arc) {
        this._center = arc._center;
        this._radius = arc._radius;
        this._start_angle = arc._start_angle;
        this._end_angle = arc._end_angle;
        this._reversed = arc._reversed;
    }
    //获取凸度值;
    getBulge() {
        var bulge = Math.tan(Math.abs(this.getSweep()) / 4.0);
        if (this.isReversed) {
            bulge *= -1;
        }
        return bulge;
    }
    //获取扫过的弧度值;
    getSweep() {
        var ret = 0.0;
        if (this._reversed) {
            if (this._start_angle <= this._end_angle) {
                ret = -(this._start_angle + 2 * Math.PI - this._end_angle);
            }
            else {
                ret = -(this._start_angle - this._end_angle);
            }
        }
        else {
            if (this._end_angle <= this._start_angle) {
                ret = this._end_angle + 2 * Math.PI - this._start_angle;
            }
            else {
                ret = this._end_angle - this._start_angle;
            }
        }
        return ret;
    }
    //方向反转;
    reverse() {
        var a = this._start_angle;
        this._start_angle = this._end_angle;
        this._end_angle = a;
        this._reversed = !this._reversed;
        return true;
    }
    moveStartPoint(pos, keepRadius = true) {
        if (!keepRadius) {
            let a = RArc.createFrom3Points(pos, this.getMiddlePoint(), this.getEndPoint());
            if (a == null) {
                return;
            }
            if (a.isReversed != this.isReversed) {
                a.reverse();
            }
            this.cloneFrom(a);
        }
        else {
            let bulge = this.getBulge();
            // full circle: trim instead of move:
            if (bulge < 1.0e-6 || bulge > 1.0e6) {
                this._start_angle = this._center.getAngleTo(pos);
            }
            else {
                let a = RArc.createFrom2PBulge(pos, this.getEndPoint(), bulge);
                if (a != null) {
                    this.cloneFrom(a);
                }
            }
        }
    }
    moveEndPoint(pos, keepRadius) {
        if (!keepRadius) {
            let a = RArc.createFrom3Points(pos, this.getMiddlePoint(), this.getStartPoint());
            if (a == null) {
                return;
            }
            if (a.isReversed != this.isReversed) {
                a.reverse();
            }
            this.cloneFrom(a);
        }
        else {
            let bulge = this.getBulge();
            // full circle: trim instead of move:
            if (bulge < 1.0e-6 || bulge > 1.0e6) {
                this._end_angle = this._center.getAngleTo(pos);
            }
            else {
                let a = RArc.createFrom2PBulge(this.getStartPoint(), pos, bulge);
            }
        }
    }
    moveMiddlePoint(pos) {
        var a = RArc.createFrom3Points(this.getStartPoint(), pos, this.getEndPoint());
        if (a != null) {
            this.cloneFrom(a);
        }
    }
    moveReferencePoint(referencePoint, targetPoint) {
        var ret = false;
        if (referencePoint.equalsFuzzy(this._center)) {
            this._center = targetPoint;
            ret = true;
        }
        else if (referencePoint.equalsFuzzy(this.getStartPoint())) {
            this.moveStartPoint(targetPoint, false);
            ret = true;
        }
        else if (referencePoint.equalsFuzzy(this.getEndPoint())) {
            this.moveEndPoint(targetPoint, false);
            ret = true;
        }
        else if (referencePoint.equalsFuzzy(this._center.plus(new ltpoint_1.LTPoint(this._radius, 0))) ||
            referencePoint.equalsFuzzy(this._center.plus(new ltpoint_1.LTPoint(0, this._radius))) ||
            referencePoint.equalsFuzzy(this._center.minus(new ltpoint_1.LTPoint(this._radius, 0))) ||
            referencePoint.equalsFuzzy(this._center.minus(new ltpoint_1.LTPoint(0, this._radius)))) {
            this._radius = this._center.getDistanceTo(targetPoint);
            ret = true;
        }
        else if (referencePoint.equalsFuzzy(this.getMiddlePoint())) {
            this.moveMiddlePoint(targetPoint);
            ret = true;
        }
        return ret;
    }
    get isReversed() {
        return this._reversed;
    }
    set isReversed(reversed) {
        this._reversed = reversed;
    }
    getStartAngle() {
        return this._start_angle;
    }
    getEndAngle() {
        return this._end_angle;
    }
    setStartAngle(a) {
        this._start_angle = ltmath_js_1.LTMath.getNormalizedAngle(a);
    }
    setEndAngle(a) {
        this._end_angle = ltmath_js_1.LTMath.getNormalizedAngle(a);
    }
    getPointsWithDistanceToEnd(distance, from) {
        var ret = [];
        if (this._radius < 1e-9) {
            return ret;
        }
        var a1;
        var a2;
        var p = new ltpoint_1.LTPoint(0, 0);
        var aDist = distance / this._radius;
        if (this.isReversed) {
            a1 = this.getStartAngle() - aDist;
            a2 = this.getEndAngle() + aDist;
        }
        else {
            a1 = this.getStartAngle() + aDist;
            a2 = this.getEndAngle() - aDist;
        }
        if (from & From.FromStart) {
            p.setPolar(this._radius, a1);
            p = p.plus(this._center);
            ret.push(p);
        }
        if (from & From.FromEnd) {
            p.setPolar(this._radius, a2);
            p = p.plus(this._center);
            ret.push(p);
        }
        return ret;
    }
    move(offset) {
        if (offset == null || offset.getMagnitude() < 1e-9) {
            return false;
        }
        this._center = this._center.plus(offset);
        return true;
    }
    isFullCircle(tolerance = 1e-3) {
        return (Math.abs(ltmath_js_1.LTMath.getAngleDifference180(ltmath_js_1.LTMath.getNormalizedAngle(this._start_angle), ltmath_js_1.LTMath.getNormalizedAngle(this._end_angle))) < tolerance);
    }
    rotate(rotation, c) {
        if (Math.abs(rotation) < 1e-7) {
            return false;
        }
        this._center.rotate(rotation, c);
        // important for circle shaped in hatch boundaries:
        if (!this.isFullCircle()) {
            this._start_angle = ltmath_js_1.LTMath.getNormalizedAngle(this._start_angle + rotation);
            this._end_angle = ltmath_js_1.LTMath.getNormalizedAngle(this._end_angle + rotation);
        }
        return true;
    }
    //弧度转换为线段;
    approximateWithLines(segmentLength, angle = 0) {
        let polyline = new RPolyline([]);
        let aStep;
        if (segmentLength < ltutils_1.PointTolerance && angle > ltutils_1.PointTolerance) {
            aStep = angle;
        }
        else {
            if (segmentLength > 0.0 && segmentLength < 1.0e-6) {
                segmentLength = 1.0e-6;
            }
            if (segmentLength > 0.0) {
                aStep = segmentLength / this._radius;
            }
            else {
                aStep = 1.0;
            }
        }
        let a1 = this.getStartAngle();
        let a2 = this.getEndAngle();
        let a, cix, ciy;
        polyline.appendVertex(this.getStartPoint());
        if (!this._reversed) {
            if (a1 > a2 - 1.0e-10) {
                a2 += 2 * Math.PI;
            }
            for (a = a1 + aStep; a <= a2; a += aStep) {
                cix = this._center.x + Math.cos(a) * this._radius;
                ciy = this._center.y + Math.sin(a) * this._radius;
                polyline.appendVertex(new ltpoint_1.LTPoint(cix, ciy));
            }
        }
        else {
            if (a1 < a2 + 1.0e-10) {
                a2 -= 2 * Math.PI;
            }
            for (a = a1 - aStep; a >= a2; a -= aStep) {
                cix = this._center.x + Math.cos(a) * this._radius;
                ciy = this._center.y + Math.sin(a) * this._radius;
                polyline.appendVertex(new ltpoint_1.LTPoint(cix, ciy));
            }
        }
        polyline.appendVertex(this.getEndPoint());
        return new ltentity_1.LTPolyline(polyline);
    }
    getHull() {
        let line = this.approximateWithLines(0, 5 * Math.PI / 180.0);
        return (0, converthull_1.convertHull)(line.subData().vertices);
    }
    scale(scaleFactors, c) {
        // negative scaling: mirroring and scaling
        if (scaleFactors.x < 0.0) {
            this.mirror(new RLine(this._center, this._center.plus(new ltpoint_1.LTPoint(0.0, 1.0))));
        }
        if (scaleFactors.y < 0.0) {
            this.mirror(new RLine(this._center, this._center.plus(new ltpoint_1.LTPoint(1.0, 0.0))));
        }
        this._center.scale(scaleFactors.x, scaleFactors.y, c);
        this._radius *= scaleFactors.x;
        if (this._radius < 0.0) {
            this._radius *= -1.0;
        }
        return true;
    }
    flipHorizontal() {
        let axis = new RLine(new ltpoint_1.LTPoint(0, 0), new ltpoint_1.LTPoint(0, 1));
        this._mirror(axis);
    }
    flipVertical() {
        let axis = new RLine(new ltpoint_1.LTPoint(0, 0), new ltpoint_1.LTPoint(1, 0));
        this._mirror(axis);
    }
    _mirror(axis) {
        if (this.isFullCircle()) {
            return true;
        }
        this._reversed = !this._reversed;
        var v = new ltpoint_1.LTPoint(0, 0);
        v.setPolar(1.0, this._start_angle);
        var line = new RLine(new ltpoint_1.LTPoint(0.0, 0.0), axis.getEndPoint().minus(axis.getStartPoint()));
        v.mirror(line);
        this._start_angle = v.getAngle();
        v.setPolar(1.0, this._end_angle);
        v.mirror(line);
        this._end_angle = v.getAngle();
        return true;
    }
    mirror(axis) {
        this._center.mirror(axis);
        this._mirror(axis);
        return true;
    }
    //trim
    getTrimEnd(trimPoint, clickPoint) {
        var angleToTrimPoint = this._center.getAngleTo(trimPoint);
        var angleToClickPoint = this._center.getAngleTo(clickPoint);
        if (ltmath_js_1.LTMath.getAngleDifference(angleToClickPoint, angleToTrimPoint) > Math.PI) {
            if (this._reversed) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if (this._reversed) {
                return false;
            }
            else {
                return true;
            }
        }
    }
    trimStartPoint(trimPoint, clickPoint, extend) {
        this._start_angle = this._center.getAngleTo(trimPoint);
        return true;
    }
    trimEndPoint(trimPoint, clickPoint, extend) {
        this._end_angle = this._center.getAngleTo(trimPoint);
        return true;
    }
    getDirection1() {
        if (!this.reverse) {
            return ltmath_js_1.LTMath.getNormalizedAngle(this._start_angle + Math.PI / 2.0);
        }
        else {
            return ltmath_js_1.LTMath.getNormalizedAngle(this._start_angle - Math.PI / 2.0);
        }
    }
    getDirection2() {
        if (!this.reverse) {
            return ltmath_js_1.LTMath.getNormalizedAngle(this._end_angle - Math.PI / 2.0);
        }
        else {
            return ltmath_js_1.LTMath.getNormalizedAngle(this._end_angle + Math.PI / 2.0);
        }
    }
}
exports.RArc = RArc;
class REllipse extends Shape {
    constructor(center, majorPoint, ratio, startParam, endParam, reversed) {
        super();
        this.pattern = null;
        this._center = center;
        this._majorPoint = majorPoint;
        this._ratio = ratio;
        this._startParam = startParam;
        this._endParam = endParam;
        this._reversed = reversed;
    }
    //获取中心点
    getPointsWithDistanceToEnd(distance, from = From.FromAny) {
        return [];
    }
    getShapeType() {
        return ltutils_1.ShapeType.Ellipse;
    }
    getShapeName() {
        return "REllipse";
    }
    getEntityName() {
        return "AcDbEllipse";
    }
    getDirection1() {
        return this.getAngleAtPoint(this.getStartPoint());
    }
    getDirection2() {
        return ltmath_js_1.LTMath.getNormalizedAngle(this.getAngleAtPoint(this.getEndPoint()) + Math.PI);
    }
    getSideOfPoint(point) {
        if (this.contains(point)) {
            if (!this._reversed) {
                return Side.RightHand;
            }
            else {
                return Side.LeftHand;
            }
        }
        else {
            if (!this._reversed) {
                return Side.LeftHand;
            }
            else {
                return Side.RightHand;
            }
        }
    }
    getTangents(point) {
        var ret = [];
        if (this.getDistanceTo(point, false) < ltutils_1.PointTolerance) {
            // point is on ellipse:
            return ret;
        }
        if (point.getDistanceTo(this.getCenter()) < ltutils_1.PointTolerance) {
            return ret;
        }
        let minorAxis = new RLine(this.getCenter(), this.getCenter().plus(this.getMinorPoint()));
        let majorAxis = new RLine(this.getCenter(), this.getCenter().plus(this.getMajorPoint()));
        if (minorAxis.isOnShape(point, false) && !majorAxis.isOnShape(point, false)) {
            let e2 = this.clone();
            e2._majorPoint = this.getMinorPoint();
            e2._ratio = 1.0 / this._ratio;
            return e2.getTangents(point);
        }
        let a = this.getMajorRadius(); // the length of the major axis / 2
        let b = this.getMinorRadius(); // the length of the minor axis / 2
        // rotate and move point:
        let point2 = point.clone();
        point2.move(-this.getCenter().x, -this.getCenter().y);
        point2.rotate(-this.getAngle());
        let xp = point2.x; // coordinates of the given point
        let yp = point2.y;
        let xt1 = 0; // Tangent point 1
        let yt1 = 0;
        let xt2 = 0; // Tangent point 2
        let yt2 = 0;
        let a2 = a * a;
        let b2 = b * b;
        let d = a2 / b2 * yp / xp;
        let e = a2 / xp;
        let af = b2 * d * d + a2;
        let bf = -b2 * d * e * 2.0;
        let cf = b2 * e * e - a2 * b2;
        let t = Math.sqrt(bf * bf - af * cf * 4.0);
        if (isNaN(t)) {
            return ret;
        }
        yt1 = (t - bf) / (af * 2.0);
        xt1 = e - d * yt1;
        yt2 = (-t - bf) / (af * 2.0);
        xt2 = e - d * yt2;
        let s1 = new ltpoint_1.LTPoint(xt1, yt1);
        s1.rotate(this.getAngle());
        s1.move(this.getCenter().x, this.getCenter().y);
        let s2 = new ltpoint_1.LTPoint(xt2, yt2);
        s2.rotate(this.getAngle());
        s2.move(this.getCenter().x, this.getCenter().y);
        if (s1.isValid()) {
            ret.push(new RLine(point, s1));
        }
        if (s2.isValid()) {
            ret.push(new RLine(point, s2));
        }
        return ret;
    }
    correctMajorMinor() {
        if (this._ratio > 1.0) {
            var mp = this.getMinorPoint();
            this._ratio = 1.0 / this._ratio;
            this.setMajorPoint(mp);
            this._startParam = ltmath_js_1.LTMath.getNormalizedAngle(this._startParam - Math.PI / 2.0);
            this._endParam = ltmath_js_1.LTMath.getNormalizedAngle(this._endParam - Math.PI / 2.0);
        }
    }
    clone() {
        var eli = new REllipse(this._center.clone(), this._majorPoint.clone(), this._ratio, this._startParam, this._endParam, this._reversed);
        return eli;
    }
    setCenter(center) {
        this._center = center;
    }
    getCenter() {
        return this._center;
    }
    getExploded() {
        return [];
    }
    setMajorPoint(p) {
        this._majorPoint = p;
        this.correctMajorMinor();
    }
    //切换长短轴
    switchMajorMinor() {
        if (Math.abs(this._ratio) < ltutils_1.PointTolerance) {
            return false;
        }
        let vp_start = this.getStartPoint();
        let vp_end = this.getStartPoint();
        let vp = this.getMajorPoint();
        this.setMajorPoint(new ltpoint_1.LTPoint(-this._ratio * vp.y, this._ratio * vp.x));
        this.setRatio(1.0 / this._ratio);
        this.setStartParam(this.getParamTo(vp_start));
        this.setEndParam(this.getParamTo(vp_end));
        return true;
    }
    //获取镜像点;
    getMinorPoint() {
        var angle = ltmath_js_1.LTMath.getNormalizedAngle(this.getAngle() + Math.PI / 2.0);
        var ret = new ltpoint_1.LTPoint(0, 0);
        ret.setPolar(this.getMinorRadius(), angle);
        return ret;
    }
    setMinorPoint(p) {
        var angle = ltmath_js_1.LTMath.getNormalizedAngle(p.getAngle() - Math.PI / 2.0);
        this._majorPoint.setPolar(this.getMajorRadius(), angle);
        this.setRatio(p.getMagnitude() / this.getMajorRadius());
    }
    setRatio(ratio) {
        if (ratio == 0) {
            return;
        }
        this._ratio = ratio;
        this.correctMajorMinor();
    }
    getRatio() {
        return this._ratio;
    }
    getMajorRadius() {
        return this._majorPoint.getMagnitude();
    }
    getMinorRadius() {
        return this._majorPoint.getMagnitude() * this._ratio;
    }
    getAngle() {
        return this._majorPoint.getAngle();
    }
    getStartParam() {
        return this._startParam;
    }
    getEndParam() {
        return this._endParam;
    }
    isReversed() {
        return this._reversed;
    }
    setAngle(a) {
        this._majorPoint = ltpoint_1.LTPoint.createPolar(this._majorPoint.getMagnitude(), a);
    }
    isFullEllipse() {
        var a1 = ltmath_js_1.LTMath.getNormalizedAngle(this._startParam);
        var a2 = ltmath_js_1.LTMath.getNormalizedAngle(this._endParam);
        return (a1 < ltutils_1.AngleTolerance && a2 > 2 * Math.PI - ltutils_1.AngleTolerance) ||
            (Math.abs(a1 - a2) < ltutils_1.AngleTolerance);
    }
    getLength() {
        var a1, a2;
        if (this.isFullEllipse()) {
            a1 = 0.0;
            a2 = 2 * Math.PI;
            var a = this.getMajorRadius();
            var b = this.getMinorRadius();
            if (ltmath_js_1.LTMath.fuzzyCompare(a, b, 0.0)) {
                return 0.0;
            }
            var h = Math.pow((a - b) / (a + b), 2);
            return Math.PI * (a + b) * ((135168 - 85760 * h - 5568 * h * h + 3867 * h * h * h) /
                (135168 - 119552 * h + 22208 * h * h - 345 * h * h * h));
        }
        else {
            a1 = ltmath_js_1.LTMath.getNormalizedAngle(this._startParam);
            a2 = ltmath_js_1.LTMath.getNormalizedAngle(this._endParam);
        }
        if (this._reversed) {
            var t = a1;
            a1 = a2;
            a2 = t;
        }
        if (ltmath_js_1.LTMath.fuzzyCompare(a1, a2, 0.0)) {
            a2 = 2 * Math.PI;
        }
        if (Math.abs(a1 - a2) < ltutils_1.AngleTolerance) {
            return 0.0;
        }
        if (a1 < a2) {
            if (a1 < Math.PI && a2 <= Math.PI) {
                return this.getSimpsonLength(a1, a2);
            }
            if (a1 < Math.PI && a2 > Math.PI) {
                return this.getSimpsonLength(a1, Math.PI) + this.getSimpsonLength(Math.PI, a2);
            }
            if (a1 >= Math.PI && a2 > Math.PI) {
                return this.getSimpsonLength(a1, a2);
            }
        }
        else {
            if (a1 > Math.PI && a2 < Math.PI) {
                return this.getSimpsonLength(a1, 2 * Math.PI) + this.getSimpsonLength(0, a2);
            }
            if (a1 > Math.PI && a2 > Math.PI) {
                return this.getSimpsonLength(a1, 2 * Math.PI) + this.getSimpsonLength(0, Math.PI) + this.getSimpsonLength(Math.PI, a2);
            }
            if (a1 < Math.PI && a2 < Math.PI) {
                return this.getSimpsonLength(a1, Math.PI) + this.getSimpsonLength(Math.PI, 2 * Math.PI) + this.getSimpsonLength(0, a2);
            }
        }
        return RNANDOUBLE;
    }
    getSimpsonLength(a1, a2) {
        var interval = 20;
        var df = (a2 - a1) / interval;
        var majorR = this.getMajorRadius();
        var minorR = this.getMinorRadius();
        var sum = 0.0;
        var q = 1.0;
        for (var i = 0; i <= interval; ++i) {
            var y = Math.sqrt(Math.pow(majorR * Math.sin(a1 + i * df), 2) + Math.pow(minorR * Math.sin(a1 + i * df), 2));
            if (i == 0 || i == interval) {
                q = 1.0;
            }
            else {
                if (i % 2 == 0) {
                    q = 2.0;
                }
                else {
                    q = 4.0;
                }
            }
            sum += q * y;
        }
        return (df / 3.0) * sum;
    }
    contains(p) {
        var pt = p.clone();
        pt.move(-this._center.x, -this._center.y);
        pt.rotate(-this.getAngle());
        var rx = this.getMajorRadius();
        var ry = this.getMinorRadius();
        return (pt.x * pt.x) / (rx * rx) + (pt.y * pt.y) / (ry * ry) <= 1.0;
    }
    getMajorPoint() {
        return this._majorPoint;
    }
    getFoci() {
        var temp_point = this.getMajorPoint();
        var vp = new ltpoint_1.LTPoint(temp_point.x * (1.0 - this.getRatio() * this.getRatio()), temp_point.y * (1.0 - this.getRatio() * this.getRatio()));
        var ret = [];
        ret.push(this.getCenter().plus(vp));
        ret.push(this.getCenter().minus(vp));
        return ret;
    }
    getReferencePoints() {
        var ret = [];
        ret.push(this._center);
        if (!this.isFullEllipse()) {
            ret.push(this.getStartPoint());
            ret.push(this.getEndPoint());
        }
        else {
            ret.push(this._center.plus(this._majorPoint));
            ret.push(this._center.minus(this._majorPoint));
            ret.push(this._center.plus(this.getMinorPoint()));
            ret.push(this._center.minus(this.getMinorPoint()));
        }
        return ret;
    }
    moveStartPoint(pos, changeAngleOnly) {
        if (changeAngleOnly) {
            this._startParam = this.getParamTo(pos);
        }
        else {
            let ep = this.getEndPoint();
            let distOri = ep.getDistanceTo(this.getStartPoint());
            let angleOri = ep.getAngleTo(this.getStartPoint());
            if (distOri < ltutils_1.PointTolerance) {
                return;
            }
            let distNew = ep.getDistanceTo(pos);
            let angleNew = ep.getAngleTo(pos);
            let factor = distNew / distOri;
            if (factor < ltutils_1.PointTolerance) {
                return;
            }
            let angleDelta = angleNew - angleOri;
            this._center.scale(factor, factor, ep);
            this._center.rotate(angleDelta, ep);
            this._majorPoint.scale(factor, factor);
            this._majorPoint.rotate(angleDelta);
        }
    }
    moveEndPoint(pos, changeAngleOnly) {
        if (changeAngleOnly) {
            this._endParam = this.getParamTo(pos);
        }
        else {
            let sp = this.getStartPoint();
            let distOri = sp.getDistanceTo(this.getEndPoint());
            let angleOri = sp.getAngleTo(this.getEndPoint());
            if (distOri < ltutils_1.PointTolerance) {
                return;
            }
            let distNew = sp.getDistanceTo(pos);
            let angleNew = sp.getAngleTo(pos);
            let factor = distNew / distOri;
            if (factor < ltutils_1.PointTolerance) {
                return;
            }
            let angleDelta = angleNew - angleOri;
            this._center.scale(factor, factor, sp);
            this._center.rotate(angleDelta, sp);
            this._majorPoint.scale(factor, factor);
            this._majorPoint.rotate(angleDelta);
        }
    }
    moveReferencePoint(referencePoint, targetPoint) {
        var startPoint = this.getStartPoint();
        var endPoint = this.getEndPoint();
        if (!this.isFullEllipse()) {
            if (referencePoint.equalsFuzzy(startPoint)) {
                this.moveStartPoint(targetPoint, true);
                return true;
            }
            if (referencePoint.equalsFuzzy(endPoint)) {
                this.moveEndPoint(targetPoint, true);
                return true;
            }
        }
        if (referencePoint.equalsFuzzy(this._center.plus(this._majorPoint))) {
            var minorRadius = this.getMinorRadius();
            this.setMajorPoint(targetPoint.minus(this._center));
            this.setRatio(minorRadius / this.getMajorRadius());
            return true;
        }
        if (referencePoint.equalsFuzzy(this._center.minus(this._majorPoint))) {
            var minorRadius = this.getMinorRadius();
            var sub_point = targetPoint.minus(this._center);
            this.setMajorPoint(new ltpoint_1.LTPoint(-sub_point.x, -sub_point.y));
            this.setRatio(minorRadius / this.getMajorRadius());
            return true;
        }
        if (referencePoint.equalsFuzzy(this._center.plus(this.getMinorPoint()))) {
            this.setMinorPoint(targetPoint.minus(this._center));
            return true;
        }
        if (referencePoint.equalsFuzzy(this._center.minus(this.getMinorPoint()))) {
            var sub_point = targetPoint.minus(this._center);
            this.setMinorPoint(new ltpoint_1.LTPoint(-sub_point.x, -sub_point.y));
            return true;
        }
        if (referencePoint.equalsFuzzy(this._center)) {
            this._center = targetPoint;
            return true;
        }
        return false;
    }
    intersectsWith(shape) {
        let p = this.getIntersectionPoints(shape, true);
        return p.length > 0;
    }
    getVectorTo(point, limited, strictRange = RNANDOUBLE) {
        let ret = {};
        let ang = this.getAngle();
        //double dDistance = RMAXDOUBLE;
        let swap = false;
        let majorSwap = false;
        let normalized = point.minus(this._center).rotate(-ang);
        // special case: point in line with major axis:
        if (Math.abs(normalized.getAngle()) < ltutils_1.AngleTolerance || Math.abs(normalized.getAngle()) > 2 * Math.PI - ltutils_1.AngleTolerance) {
            ret = new ltpoint_1.LTPoint(this.getMajorRadius(), 0.0);
            //dDistance = ret.distanceTo(normalized);
        }
        else if (Math.abs(normalized.getAngle() - Math.PI) < ltutils_1.AngleTolerance) {
            ret = new ltpoint_1.LTPoint(-this.getMajorRadius(), 0.0);
            //dDistance = ret.distanceTo(normalized);
        }
        else {
            let dU = normalized.x;
            let dV = normalized.y;
            let dA = this.getMajorRadius();
            let dB = this.getMinorRadius();
            let dEpsilon = 1.0e-8;
            // iteration maximum
            let iMax = 32;
            let rdX = 0.0;
            let rdY = 0.0;
            if (dA < dB) {
                var dum = dA;
                dA = dB;
                dB = dum;
                dum = dU;
                dU = dV;
                dV = dum;
                majorSwap = true;
            }
            if (dV < 0.0) {
                dV *= -1.0;
                swap = true;
            }
            // initial guess:
            var dT = dB * (dV - dB);
            // newton's method:
            let i = 0;
            for (i = 0; i < iMax; i++) {
                let dTpASqr = dT + dA * dA;
                let dTpBSqr = dT + dB * dB;
                let dInvTpASqr = 1.0 / dTpASqr;
                let dInvTpBSqr = 1.0 / dTpBSqr;
                let dXDivA = dA * dU * dInvTpASqr;
                let dYDivB = dB * dV * dInvTpBSqr;
                let dXDivASqr = dXDivA * dXDivA;
                let dYDivBSqr = dYDivB * dYDivB;
                let dF = dXDivASqr + dYDivBSqr - 1.0;
                if (Math.abs(dF) < dEpsilon) {
                    // f(t0) is very close to zero:
                    rdX = dXDivA * dA;
                    rdY = dYDivB * dB;
                    break;
                }
                let dFDer = 2.0 * (dXDivASqr * dInvTpASqr + dYDivBSqr * dInvTpBSqr);
                let dRatio = dF / dFDer;
                if (Math.abs(dRatio) < dEpsilon) {
                    // t1-t0 is very close to zero:
                    rdX = dXDivA * dA;
                    rdY = dYDivB * dB;
                    break;
                }
                dT += dRatio;
            }
            if (i == iMax) {
                ret = ltpoint_1.LTPoint.nullVector();
            }
            else {
                ret = new ltpoint_1.LTPoint(rdX, rdY);
            }
        }
        if (ret) {
            if (swap) {
                ret.y *= -1.0;
            }
            if (majorSwap) {
                var dum = ret.x;
                ret.x = ret.y;
                ret.y = dum;
            }
            ret = (ret.rotate(ang).plus(this._center));
            if (limited) {
                var a1 = this._center.getAngleTo(this.getStartPoint());
                var a2 = this._center.getAngleTo(this.getEndPoint());
                var a = this._center.getAngleTo(ret);
                if (!ltmath_js_1.LTMath.isAngleBetween(a, a1, a2, this._reversed)) {
                    ret = {};
                }
            }
        }
        return point.minus(ret);
    }
    move(offset) {
        if (offset == null || offset.getMagnitude() < ltutils_1.PointTolerance) {
            return false;
        }
        this._center = this._center.plus(offset);
        return true;
    }
    rotate(rotation, c = null) {
        if (Math.abs(rotation) < ltutils_1.AngleTolerance) {
            return false;
        }
        this._center.rotate(rotation, c);
        this._majorPoint.rotate(rotation);
        return true;
    }
    scale(scaleFactors, c) {
        if (Math.abs(Math.abs(scaleFactors.x) - Math.abs(scaleFactors.y)) > ltutils_1.PointTolerance) {
            return false;
        }
        if (scaleFactors.x < 0.0) {
            this.mirror(new RLine(this._center, this._center.plus(new ltpoint_1.LTPoint(0.0, 1.0))));
        }
        if (scaleFactors.y < 0.0) {
            this.mirror(new RLine(this._center, this._center.plus(new ltpoint_1.LTPoint(1.0, 0.0))));
        }
        this._center.scale(scaleFactors.x, scaleFactors.y, c);
        //oldMinorPoint.scale(scaleFactors);
        var f = new ltpoint_1.LTPoint(Math.abs(scaleFactors.x), Math.abs(scaleFactors.y));
        this._majorPoint.scale(f.x, f.y);
        return true;
    }
    getAngleAtPoint(pos) {
        var posNormalized = pos.clone();
        posNormalized.move(-this._center.x, -this._center.y);
        posNormalized.rotate(-this.getAngle());
        var angle;
        if (ltmath_js_1.LTMath.fuzzyCompare(posNormalized.y, 0.0, 0.0)) {
            if (posNormalized.x > 0) {
                angle = Math.PI / 2;
            }
            else {
                angle = Math.PI / 2 * 3;
            }
        }
        else {
            var slope = -(Math.pow(this.getMinorRadius() * 2, 2) * posNormalized.x) / (Math.pow(this.getMajorRadius() * 2, 2) * posNormalized.y);
            angle = Math.atan(slope) + Math.PI;
        }
        if (this._reversed) {
            angle += Math.PI;
        }
        if (posNormalized.y < 0) {
            angle += Math.PI;
        }
        angle += this.getAngle();
        return ltmath_js_1.LTMath.getNormalizedAngle(angle);
    }
    // previously: getEllipseAngle
    getParamTo(pos) {
        var m = pos.clone();
        m.rotate(-this._majorPoint.getAngle(), this._center);
        var v = m.minus(this._center);
        var sacle_pos = new ltpoint_1.LTPoint(1.0, 1.0 / this._ratio);
        v.scale(sacle_pos.x, sacle_pos.y);
        return v.getAngle();
    }
    mirror(axis) {
        var mp = this._center.plus(this._majorPoint);
        var sp = this.getStartPoint();
        var ep = this.getEndPoint();
        this._center.mirror(axis);
        mp.mirror(axis);
        this._majorPoint = mp.minus(this._center);
        if (!this.isFullEllipse()) {
            this._reversed = (!this._reversed);
            sp.mirror(axis);
            this.setStartParam(this.getParamTo(sp));
            ep.mirror(axis);
            this.setEndParam(this.getParamTo(ep));
        }
        return true;
    }
    setStartParam(param) {
        this._startParam = param;
    }
    setEndParam(param) {
        this._endParam = param;
    }
    reverse() {
        var a = this._startParam;
        this._startParam = this._endParam;
        this._endParam = a;
        this._reversed = !this._reversed;
        return true;
    }
    getBoundingBox() {
        let radius1 = this.getMajorRadius();
        let radius2 = this.getMinorRadius();
        let angle = this.getAngle();
        let a1 = ((!this.isReversed()) ? this._startParam : this._endParam);
        let a2 = ((!this.isReversed()) ? this._endParam : this._startParam);
        let startPoint = this.getStartPoint();
        let endPoint = this.getEndPoint();
        let minX = Math.min(startPoint.x, endPoint.x);
        let minY = Math.min(startPoint.y, endPoint.y);
        let maxX = Math.max(startPoint.x, endPoint.x);
        let maxY = Math.max(startPoint.y, endPoint.y);
        // kind of a brute force. TODO: exact calculation
        let vp;
        let a = a1;
        do {
            vp = new ltpoint_1.LTPoint(this._center.x + radius1 * Math.cos(a), this._center.y + radius2 * Math.sin(a));
            vp.rotate(angle, this._center);
            minX = Math.min(minX, vp.x);
            minY = Math.min(minY, vp.y);
            maxX = Math.max(maxX, vp.x);
            maxY = Math.max(maxY, vp.y);
            a += 0.03;
        } while (ltmath_js_1.LTMath.isAngleBetween(a, a1, a2, false) && a < 4 * Math.PI);
        return ltpoint_1.RBox.formVectors(new ltpoint_1.LTPoint(minX, minY), new ltpoint_1.LTPoint(maxX, maxY));
    }
    getShapes(query_box, ignoreComplex, issegment) {
        let ret = [];
        ret.push(this.clone());
        return ret;
    }
    //获取扫描的凸度;
    getSweep() {
        var ret = 0.0;
        if (this._reversed) {
            if (this._startParam <= this._endParam) {
                ret = -(this._startParam + 2 * Math.PI - this._endParam);
            }
            else {
                ret = -(this._startParam - this._endParam);
            }
        }
        else {
            if (this._endParam <= this._startParam) {
                ret = this._endParam + 2 * Math.PI - this._startParam;
            }
            else {
                ret = this._endParam - this._startParam;
            }
        }
        return ret;
    }
    //trim
    getTrimEnd(trimPoint, clickPoint) {
        var paramToClickPoint = this.getParamTo(clickPoint);
        var paramToTrimPoint = this.getParamTo(trimPoint);
        if (ltmath_js_1.LTMath.getAngleDifference(paramToTrimPoint, paramToClickPoint) > Math.PI) {
            return false;
        }
        else {
            return true;
        }
    }
    trimStartPoint(trimPoint, clickPoint, extend) {
        this._startParam = this.getParamTo(trimPoint);
        return true;
    }
    trimEndPoint(trimPoint, clickPoint, extend) {
        this._endParam = this.getParamTo(trimPoint);
        return true;
    }
    getCenterPoints() {
        let ret = [];
        ret.push(this.getCenter());
        return ret;
    }
    getStartPoint() {
        let ret = new ltpoint_1.LTPoint(this._center.x + Math.cos(this._startParam) * this.getMajorRadius(), this._center.y + Math.sin(this._startParam) * this.getMinorRadius());
        ret.rotate(this.getAngle(), this._center);
        return ret;
    }
    getEndPoint() {
        let ret = new ltpoint_1.LTPoint(this._center.x + Math.cos(this._endParam) * this.getMajorRadius(), this._center.y + Math.sin(this._endParam) * this.getMinorRadius());
        ret.rotate(this.getAngle(), this._center);
        return ret;
    }
    getSaveValue() {
        let dict = [];
        const json = {
            "center": this._center.toJson(),
            "endParam": this._endParam, "startParam": this._startParam,
            "majorPoint": this._majorPoint.toJson(),
            "radiusRatio": this._ratio
        };
        let str = JSON.stringify(json);
        dict[ltfields_1.FieldNames.Cadgeo] = str;
        return dict;
    }
}
exports.REllipse = REllipse;
class RHatch extends Shape {
    constructor(vertices) {
        super();
        this._pattern = null;
        this._isClip = false;
        this._outline = [];
        if (vertices != null && vertices != undefined) {
            let p = new RPolyline(vertices);
            p._isPolygion = true;
            this._outline.push(p);
        }
        this._closed = false;
        this.originPoint = new ltpoint_1.LTPoint(0, 0);
        this._hatchname = "";
        this._rotate = 0;
        this._patternScale = 1;
    }
    setPatternScale(sclae) {
        this._patternScale = sclae;
    }
    setClip(isclip) {
        if (isclip === undefined) {
            return;
        }
        this._isClip = isclip;
    }
    getDirection1() {
        return 0;
    }
    getDirection2() {
        return 0;
    }
    getSideOfPoint(point) {
        return Side.NoSide;
    }
    getShapeName() {
        return "图案填充";
    }
    getEntityName() {
        return "AcDbHatch";
    }
    getExploded() {
        return [];
    }
    getLength() {
        return 0;
    }
    getPointsWithDistanceToEnd(distance, from = From.FromAny) {
        return [];
    }
    get pattern() {
        return this._pattern;
    }
    set pattern(p) {
        this._pattern = p;
    }
    get hatchName() {
        return this._hatchname;
    }
    set hatchName(h) {
        this._hatchname = h;
    }
    clone() {
        let hatch = new RHatch();
        this._outline.forEach(out => {
            hatch._outline.push(out.clone());
        });
        hatch._closed = this._closed;
        hatch._rotate = this._rotate;
        hatch._hatchname = this._hatchname;
        hatch.originPoint = this.originPoint.clone();
        if (this._pattern != null) {
            hatch._pattern = this._pattern.clone();
        }
        hatch._isClip = this._isClip;
        hatch._patternScale = this._patternScale;
        return hatch;
    }
    getShapeType() {
        return ltutils_1.ShapeType.Hatch;
    }
    isDirected() {
        return true;
    }
    addloops(line) {
        if (line instanceof RPolyline) {
            line._isPolygion = true;
        }
        this._outline.push(line);
    }
    clear() {
        this._outline = [];
    }
    moveReferencePoint(referencePoint, targetPoint) {
        this._outline.forEach(out => {
            out.moveReferencePoint(referencePoint, targetPoint);
        });
        return true;
    }
    intersectsWith(other, limited) {
        let isok = false;
        this._outline.forEach(out => {
            if (out.intersectsWith(other, limited)) {
                isok = true;
                return true;
            }
        });
        return isok;
    }
    insertVertexAt(point) { }
    countVertices() {
        let v = 0;
        this._outline.forEach(out => {
            if (out instanceof RPolyline) {
                v += out.countVertices();
            }
        });
        return v;
    }
    countSegments() {
        let v = 0;
        this._outline.forEach(out => {
            if (out instanceof RPolyline) {
                v += out.countSegments();
            }
        });
        return v;
    }
    getSegmentAt(index) {
        // let v:0;
        // this._outline.forEach(out => {
        //   v += out.getSegmentAt(index);
        // });
        // return v;
    }
    getEndPoint() {
        return this.getEndPoints()[0];
    }
    getEndPoints() {
        var v = [];
        this._outline.forEach(out => {
            out.getEndPoints().forEach(point => {
                v.push(point);
            });
        });
        return v;
    }
    getVectorTo(point, limited, strictRange = RNANDOUBLE) {
        var v = null;
        this._outline.forEach(out => {
            var point1 = out.getVectorTo(point, limited, strictRange);
            v = point;
        });
        return v;
    }
    getShapes(queryBox, ignoreComplex, issegment) {
        let v = [];
        this._outline.forEach(out => {
            let shapelist = out.getShapes(queryBox, ignoreComplex, issegment);
            shapelist.forEach(shape => {
                v.push(shape);
            });
        });
        return v;
    }
    getBoundingBox() {
        let bbox = ltpoint_1.RBox.nullBox();
        this._outline.forEach(out => {
            bbox.growToInclude(out.getBoundingBox());
        });
        return bbox;
    }
    moveVertexAt(index, offset) {
        this._outline.forEach(out => {
            if (out instanceof RPolyline) {
                out.moveVertexAt(index, offset);
            }
        });
    }
    moveSegmentAt(index, offset) {
        this._outline.forEach(out => {
            if (out instanceof RPolyline) {
                out.moveSegmentAt(index, offset);
            }
        });
    }
    getReferencePoints() {
        let v = [];
        this._outline.forEach(out => {
            let shapelist = out.getReferencePoints();
            shapelist.forEach(shape => {
                v.push(shape);
            });
        });
        return v;
    }
    move(offset) {
        this._outline.forEach(out => {
            out.move(offset);
        });
    }
    mirror(axis) {
        this._outline.forEach(out => {
            out.mirror(axis);
        });
    }
    scale(scale_factors, c = null) {
        this._outline.forEach(out => {
            out.scale(scale_factors, c);
        });
    }
    rotate(rotation, center) {
        this._outline.forEach(out => {
            out.rotate(rotation, center);
        });
    }
    getSegments(line) {
        let ret = [];
        let intersections = [];
        this._outline.forEach(out => {
            let ips = ltintersection_1.LTIntersection.getIntersectionPoints(out, line, true, false);
            for (let i = 0; i < ips.length; i++) {
                intersections.push(ips[i]);
            }
        });
        //考虑环
        if (intersections.length == 0) {
            return ret;
        }
        let angle = line.getAngle();
        if ((angle >= (Math.PI / 4.0) * 0.0 && angle <= (Math.PI / 4.0) * 1.0) ||
            (angle >= (Math.PI / 4.0) * 7.0 && angle <= (Math.PI / 4.0) * 8.0)) {
            intersections.sort((a, b) => a.x - b.x);
        }
        else if (angle >= (Math.PI / 4.0) * 3.0 &&
            angle <= (Math.PI / 4.0) * 5.0) {
            intersections.sort((a, b) => b.x - a.x);
        }
        else if (angle >= (Math.PI / 4.0) * 1.0 &&
            angle <= (Math.PI / 4.0) * 3.0) {
            intersections.sort((a, b) => a.y - b.y);
        }
        else {
            intersections.sort((a, b) => b.y - a.y);
        }
        for (let i = 0; i < intersections.length - 1; i++) {
            ret.push(new RLine(intersections[i], intersections[i + 1]));
        }
        return ret;
    }
    contains(pos) {
        let is_contain = false;
        for (let i = 0; i < this._outline.length; ++i) {
            let out = this._outline[i];
            if (out instanceof RPolyline) {
                if (out.hasArcSegments()) {
                    //判断是否在里面
                    if (out.getBoundingBox().contain(pos)) {
                        is_contain = !is_contain;
                    }
                }
                else {
                    if (ltutils_1.LTUtils.containsByPoint(out.vertices, pos) == true) {
                        is_contain = !is_contain;
                    }
                }
            }
            else if (out instanceof RCircle) {
                if (out.contains(pos) == true) {
                    is_contain = !is_contain;
                }
            }
        }
        return is_contain;
    }
    _render(canvas, outline) {
        if (outline instanceof RPolyline) {
            if (outline.hasArcSegments()) {
                let sub_shapes = outline.getShapes(null);
                canvas.drawShape(sub_shapes);
            }
            else {
                if (outline.isClosed() == false) {
                    return;
                }
                canvas.drawPolygon(outline.vertices);
            }
        }
        else if (outline instanceof RCircle) {
            canvas.drawCircle(outline.getCenter(), outline.getRadius());
        }
    }
    hasClip() {
        if (this._isClip && this._outline.length === 2) {
            return true;
        }
        return false;
    }
    render(canvas, entity, graphics, info) {
        function _renderSolid(canvas, hatch) {
            canvas.setFill(true);
            for (let i = 0; i < hatch._outline.length; ++i) {
                if (hatch.hasClip()) {
                    if (i !== 0) {
                        canvas.startClip();
                    }
                    hatch._render(canvas, hatch._outline[i]);
                    if (i !== 0) {
                        canvas.endClip();
                    }
                }
                else {
                    hatch._render(canvas, hatch._outline[i]);
                }
            }
            canvas.setFill(false);
            return false;
        }
        //裁剪块才进行屏蔽
        if (info !== undefined && info !== null && info.drawblock === true && info.clip === true) {
            if (this._outline.length > 10) {
                //_renderSolid(canvas,this);
                return;
            }
        }
        if (this._pattern === null || this._pattern.name.toLowerCase() == "solid") {
            _renderSolid(canvas, this);
            return;
        }
        let start_time = Date.now();
        let pattern = this._pattern.clone();
        pattern.rotate(this._rotate);
        pattern.scale(this._patternScale);
        let hasDots = pattern.hasDots();
        let boundaryBox = this.getBoundingBox();
        boundaryBox.grow(1.0);
        let boundaryEdges = boundaryBox.getLines2d();
        let boundaryCorners = boundaryBox.getCorners2d();
        let boundarywidth = boundaryBox.getWidth();
        let boundaryHeight = boundaryBox.getHeight();
        let patternLines = pattern.patternLines;
        for (let i = 0; i < patternLines.length; i++) {
            let patternLine = patternLines[i];
            let pat = new ltlinetypepattern_js_1.LTLinetypePattern(false, "", "");
            pat.setdash(patternLine.dashes);
            let spBase = this.originPoint.plus(patternLine.basePoint || new ltpoint_1.LTPoint(0, 0));
            let baseLine = new RLine(spBase, spBase.plus(ltpoint_1.LTPoint.createPolar(1.0, patternLine.angle)));
            // iterate through corners and find left most / right most offsets:
            let maxDistLeft = NaN;
            let minDistLeft = NaN;
            let maxDistRight = NaN;
            let minDistRight = NaN;
            canvas.setStrokeWidth(0);
            canvas.setColor(entity.entityColor(canvas.map), entity.getLayerName());
            let polyline = new ltentity_1.LTPolyline();
            polyline._linewidth = 0;
            polyline.setLayerName(entity.getLayerName());
            polyline.setColor(entity.color());
            for (let k = 0; k < boundaryCorners.length; k++) {
                let corner = boundaryCorners[k];
                let dist = baseLine.getDistanceTo(corner, false);
                let side = baseLine.getSideOfPoint(corner);
                if (side == Side.LeftHand) {
                    if (isNaN(maxDistLeft) || dist > Math.abs(maxDistLeft)) {
                        maxDistLeft = -dist;
                    }
                    if (isNaN(minDistLeft) || dist < Math.abs(minDistLeft)) {
                        minDistLeft = -dist;
                    }
                }
                if (side == Side.RightHand) {
                    if (isNaN(maxDistRight) || dist > maxDistRight) {
                        maxDistRight = dist;
                    }
                    if (isNaN(minDistRight) || dist < minDistRight) {
                        minDistRight = dist;
                    }
                }
            }
            if (isNaN(maxDistLeft)) {
                maxDistLeft = minDistRight;
            }
            if (isNaN(maxDistRight)) {
                maxDistRight = minDistLeft;
            }
            if (isNaN(maxDistLeft) || isNaN(maxDistRight)) {
                return;
            }
            if (Math.abs(patternLine.offset.y) < 1.0e-9) {
                return;
            }
            let leftMultiple = maxDistLeft / Math.abs(patternLine.offset.y) - 1;
            let rightMultiple = maxDistRight / Math.abs(patternLine.offset.y) + 1;
            let offset = patternLine.offset;
            offset.rotate(patternLine.angle);
            let offsetSide = baseLine.getSideOfPoint(baseLine.getStartPoint().plus(offset));
            if (offsetSide == Side.LeftHand) {
                let tmp = leftMultiple;
                leftMultiple = -rightMultiple;
                rightMultiple = -tmp;
            }
            for (let m = leftMultiple; m < rightMultiple; m++) {
                let currentOffset = offset.dot(Math.floor(m));
                let sp = this.originPoint
                    .plus(patternLine.basePoint || new ltpoint_1.LTPoint(0, 0))
                    .plus(currentOffset);
                let orthoLine = new RLine(sp, sp.plus(ltpoint_1.LTPoint.createPolar(1.0, patternLine.angle + Math.PI / 2.0)));
                let line = new RLine(sp, sp.plus(ltpoint_1.LTPoint.createPolar(1.0, patternLine.angle)));
                let intersections = [];
                for (let k = 0; k < boundaryEdges.length; k++) {
                    let edge = boundaryEdges[k];
                    let ips = ltintersection_1.LTIntersection.getIntersectionPoints(line, edge, false);
                    for (let ib = 0; ib < ips.length; ib++) {
                        if (edge.isOnShape(ips[ib])) {
                            intersections.push(ips[ib]);
                        }
                    }
                }
                let orthoMaxDistLeft = NaN;
                let orthoMinDistLeft = NaN;
                let orthoMaxDistRight = NaN;
                let orthoMinDistRight = NaN;
                let ipMaxLeft = {};
                let ipMinLeft = {};
                let ipMaxRight = {};
                let ipMinRight = {};
                for (let k = 0; k < intersections.length; k++) {
                    let ip = intersections[k];
                    let dist = orthoLine.getDistanceTo(ip, false);
                    let side = orthoLine.getSideOfPoint(ip);
                    if (side == Side.LeftHand) {
                        if (isNaN(orthoMaxDistLeft) || dist > Math.abs(orthoMaxDistLeft)) {
                            orthoMaxDistLeft = -dist;
                            ipMaxLeft = ip;
                        }
                        if (isNaN(orthoMinDistLeft) || dist < Math.abs(orthoMinDistLeft)) {
                            orthoMinDistLeft = -dist;
                            ipMinLeft = ip;
                        }
                    }
                    if (side == Side.RightHand) {
                        if (isNaN(orthoMaxDistRight) || dist > orthoMaxDistRight) {
                            orthoMaxDistRight = dist;
                            ipMaxRight = ip;
                        }
                        if (isNaN(orthoMinDistRight) || dist < orthoMinDistRight) {
                            orthoMinDistRight = dist;
                            ipMinRight = ip;
                        }
                    }
                }
                if (isNaN(orthoMaxDistLeft)) {
                    orthoMaxDistLeft = orthoMinDistRight;
                    ipMaxLeft = ipMinRight;
                }
                if (isNaN(orthoMaxDistRight)) {
                    orthoMaxDistRight = orthoMinDistLeft;
                    ipMaxRight = ipMinLeft;
                }
                if (isNaN(orthoMaxDistLeft) || isNaN(orthoMaxDistRight)) {
                    continue;
                }
                let unclippedLine = new RLine(ipMaxLeft, ipMaxRight);
                let segments = this.getSegments(unclippedLine);
                for (let si = 0; si < segments.length; si++) {
                    let middle = segments[si].getMiddlePoint();
                    if (this.contains(middle)) {
                        let side = orthoLine.getSideOfPoint(segments[si].getStartPoint());
                        let offsetdis = sp.getDistanceTo(segments[si].getStartPoint());
                        if (side == Side.RightHand) {
                            offsetdis *= -1;
                        }
                        let poss = [];
                        poss.push(segments[si].getStartPoint());
                        poss.push(segments[si].getEndPoint());
                        // line;
                        if (patternLine.dashes.length > 0) {
                            polyline.subData().clear();
                            polyline.subData().setVertices(poss);
                            let porter = new exporter_1.RPainterPathExporter();
                            porter.exportEntity(canvas, polyline, pat, offsetdis, graphics);
                        }
                        else {
                            canvas.drawLineString(poss);
                        }
                    }
                }
            }
            if (Date.now() - start_time > 10000) {
                console.log("hatch time out");
                return;
            }
        }
    }
    getSaveValue() {
        let dict = [];
        dict[ltfields_1.FieldNames.BlockName] = this._hatchname;
        let cadgeo = [];
        this._outline.forEach(out => {
            if (out instanceof RPolyline) {
                cadgeo.push(out._getSaveJson());
            }
            else if (out instanceof RCircle) {
                cadgeo.push(out._getSaveJson());
            }
        });
        let str = JSON.stringify(cadgeo);
        dict[ltfields_1.FieldNames.Cadgeo] = str;
        //info
        let info = { clip: this._isClip, patternAngle: this._rotate, patternScale: this._patternScale };
        dict[ltfields_1.FieldNames.Info] = JSON.stringify(info);
        return dict;
    }
}
exports.RHatch = RHatch;
class RBlockReference extends Shape {
    constructor(point) {
        super();
        this._offset = new ltpoint_1.LTPoint(0, 0);
        this.blockName = "";
        this._shapes = [];
        this._boundingbox = null;
        this._clipboundingbox = null;
        this._attribute = null;
        this.position = point;
        this._rotate = 0;
        this.scaleFactors = new ltpoint_1.LTPoint(1, 1);
        this.angle = 0;
        this.columnCount = 1;
        this.rowCount = 1;
        this.columnSpacing = 0;
        this.rowSpacing = 0;
        //需要初始化blockinfo;
        this._blockInfo = new Object();
        this._blockInfo.xsize = 1;
        this._blockInfo.ysize = 1;
        //this._blockInfo.rotate = 0;
        this._blockInfo.offset = new ltpoint_1.LTPoint(0, 0);
    }
    /**
    * \brief 设置块信息 xsize ysize
    */
    setBlockInfo(blockInfo) {
        this._blockInfo = JSON.parse(blockInfo);
        let xscale = this._blockInfo.xsize;
        let yscale = this._blockInfo.ysize;
        this.setScale(xscale, yscale);
        if (this._blockInfo.columnCount !== undefined) {
            this.columnCount = this._blockInfo.columnCount;
            this.rowCount = this._blockInfo.rowCount;
            this.columnSpacing = this._blockInfo.columnSpacing;
            this.rowSpacing = this._blockInfo.rowSpacing;
        }
        if (this._blockInfo.A !== undefined) {
            let p = new ltpoint_1.LTPoint(Number(this._blockInfo.A.alignmentx), Number(this._blockInfo.A.alignmenty));
            let t = new RText(p, this._blockInfo.A.text, this._blockInfo.A.height);
            t.textVertMode = this._blockInfo.A.vmode;
            t.textHorzMode = this._blockInfo.A.hmode;
            t.setRotate(this._blockInfo.A.rotate);
            this._attribute = t;
        }
    }
    setClipbound(box) {
        this._clipboundingbox = box;
    }
    setBlockName(name) {
        this.blockName = name;
    }
    setPoint(point) {
        this.position = point;
    }
    scale(scale_factors, c = null) {
        this.position.scale(scale_factors.x, scale_factors.y, c);
        this.scaleFactors.x = scale_factors.x * this.scaleFactors.x;
        this.scaleFactors.y = scale_factors.y * this.scaleFactors.y;
        this.columnSpacing *= scale_factors.x;
        this.rowSpacing *= scale_factors.x;
    }
    getDirection1() {
        return 0;
    }
    getDirection2() {
        return 0;
    }
    getSideOfPoint(point) {
        return Side.NoSide;
    }
    getExploded() {
        //获取打散图形;
        return this.getShapes(null, true, false);
        // let ret1: Shape[] = [];
        // ret1.push(this);
        // return ret1;
    }
    getShapeName() {
        return "块参照";
    }
    getEntityName() {
        return "AcDbBlockReference";
    }
    setScale(x, y) {
        this.scaleFactors.x = x;
        this.scaleFactors.y = y;
        this._shapes = [];
    }
    getScale() {
        return this.scaleFactors;
    }
    getShapeType() {
        return ltutils_1.ShapeType.Point;
    }
    rotate(rotation, center) {
        if (Math.abs(rotation) < 1.0e-9) {
            return false;
        }
        this.position.rotate(rotation, center);
        this.setRotate(ltmath_js_1.LTMath.getNormalizedAngle(this.getRotate() + rotation));
        //(geometry._entity_data._rotate * 180) / Math.PI) ;
        return true;
    }
    getHull() {
        if (this._shapes.length == 0) {
            this.getShapes(null);
        }
        let shapes = this.getShapes(null, true);
        let result_points = [];
        for (let i = 0; i < shapes.length; i++) {
            let polygon = shapes[i].getHull();
            result_points = result_points.concat(polygon.subData().vertices);
        }
        return (0, converthull_1.convertHull)(result_points);
    }
    clone() {
        let point = new RBlockReference(new ltpoint_1.LTPoint(this.position.x, this.position.y));
        point._rotate = this._rotate;
        point.scaleFactors = this.scaleFactors.clone();
        point.columnCount = this.columnCount;
        point.rowCount = this.rowCount;
        point.columnSpacing = this.columnSpacing;
        point.rowSpacing = this.rowSpacing;
        point.blockName = this.blockName;
        point._offset = new ltpoint_1.LTPoint(this._offset.x, this._offset.y);
        point._clipboundingbox = this._clipboundingbox;
        point._blockInfo = this._blockInfo;
        if (this._attribute !== null) {
            point._attribute = this._attribute.clone();
        }
        return point;
    }
    getBoundingBox() {
        let info = this._blockInfo;
        if (info !== undefined && info !== null && info.filter !== undefined && info.filter !== null) {
            if (info.filter.drawboundary || this._clipboundingbox == null) {
                let pos = info.filter.pos;
                let points = [];
                for (let i = 0; i < pos.length; i++) {
                    points.push(new ltpoint_1.LTPoint(Number(pos[i].x), Number(pos[i].y)));
                }
                let polygon = new ltentity_1.LTPolyline(points);
                polygon.move(this.position);
                let box = polygon.getBoundingBox();
                this.setClipbound(box);
            }
        }
        if (this._clipboundingbox !== null) {
            return this._clipboundingbox;
        }
        if (this._boundingbox == null) {
            let bbox = ltpoint_1.RBox.nullBox();
            let shapes = this.getShapes(null, true, false);
            for (var i = 0; i < shapes.length; ++i) {
                bbox.growToInclude(shapes[i].getBoundingBox());
            }
            if (this._attribute !== null) {
                bbox.growToInclude(this._attribute.getBoundingBox());
            }
            this._boundingbox = bbox;
        }
        return this._boundingbox;
    }
    getLength() {
        return 0.0;
    }
    intersectsWith(other, limited) {
        let shapes = this.getShapes(null, limited, false);
        for (var i = 0; i < shapes.length; ++i) {
            if (shapes[i].intersectsWith(other, limited)) {
                return true;
            }
        }
        if (this._attribute !== null) {
            if (this._attribute.intersectsWith(other, limited)) {
                return true;
            }
        }
        return false;
    }
    getEndPoints() {
        var ret = [];
        ret.push(this.position);
        return ret;
    }
    getEndPoint() {
        return this.position || new ltpoint_1.LTPoint(0, 0);
    }
    getMiddlePoints() {
        return this.getEndPoints();
    }
    getMiddlePoint() {
        return this.position;
    }
    getCenterPoints() {
        return this.getEndPoints();
    }
    getPointsWithDistanceToEnd(distance, from) {
        return [];
    }
    getAngleAt(distance, from) {
        return RNANDOUBLE;
    }
    getVectorTo(point, limited, strictRange) {
        if (this._shapes.length == 0) {
            this.getShapes(null);
        }
        //获取相对坐标;
        let min_distance = Number.MAX_VALUE;
        let result = null;
        for (let i = 0; i < this._shapes.length; i++) {
            let shape = this._shapes[i];
            let v = shape.getVectorTo(point, limited, strictRange);
            if (v == null) {
                continue;
            }
            if (min_distance > v.getMagnitude()) {
                min_distance = v.getMagnitude();
                result = v;
            }
        }
        return result;
    }
    getShapes(queryBox, ignoreComplex = true, issegment = false) {
        if (this._shapes.length > 0) {
            if (queryBox == null) {
                return this._shapes;
            }
            let result_shapes = [];
            this._shapes.forEach(shape => {
                if (shape.getBoundingBox().intersects(queryBox)) {
                    result_shapes.push(shape);
                }
            });
            return result_shapes;
        }
        let result_shapes = [];
        for (let col = 0; col < this.columnCount; col++) {
            for (let row = 0; row < this.rowCount; row++) {
                //获取到当前块
                let offset = this.getColumnRowOffset(col, row, true);
                let pos = this.position.moveNewPoint(offset.x, offset.y);
                let block = window.cadmap.dwgservice.getBlock(this.blockName);
                if (block != null) {
                    let clone = block.clone();
                    clone.movePoint(pos);
                    clone.scale(this.scaleFactors, pos);
                    clone.rotate(this._rotate * 1, pos);
                    clone._entitys.forEach((sub_ent) => {
                        sub_ent.subData().getShapes().forEach((shape) => {
                            if (queryBox == null) {
                                result_shapes.push(shape);
                                return;
                            }
                            if (shape.getBoundingBox().intersects(queryBox)) {
                                result_shapes.push(shape);
                            }
                        });
                    });
                }
            }
        }
        if (queryBox == null) {
            this._shapes = result_shapes;
        }
        return result_shapes;
    }
    getClosestPointOnEntity() {
        return null;
    }
    getReferencePoints() {
        let ret = [];
        ret.push(this.position);
        return ret;
    }
    moveReferencePoint(referencePoint, targetPoint) {
        let ret = false;
        if (referencePoint.equalsFuzzy(this.position) == true) {
            //获得偏移
            let offset = this.position.minus(targetPoint);
            this.position = targetPoint;
            //属性也需要跟着移动
            this._offset = this._offset.minus(offset);
            if (this._attribute !== null) {
                this._attribute.position = this._attribute.position.minus(offset);
            }
        }
        return ret;
    }
    move(offset) {
        this.position.move(offset.x, offset.y);
        if (this._attribute !== null) {
            this._attribute.move(offset);
        }
    }
    getColumnRowOffset(col, row, rotated) {
        if (col == 0 && row == 0) {
            return new ltpoint_1.LTPoint(0, 0);
        }
        let offset = new ltpoint_1.LTPoint(0, 0);
        if (ltmath_js_1.LTMath.fuzzyCompare(this.scaleFactors.x, 0, 0)) {
            offset.x = 0.0;
        }
        else {
            offset.x = col * this.columnSpacing;
            //offset.x = col*columnSpacing/scaleFactors.x;
        }
        if (ltmath_js_1.LTMath.fuzzyCompare(this.scaleFactors.y, 0.0, 0)) {
            offset.y = 0.0;
        }
        else {
            offset.y = row * this.rowSpacing;
            //offset.y = row*rowSpacing/scaleFactors.y;
        }
        if (rotated) {
            offset.rotate(this._rotate, null);
        }
        return offset;
    }
    applyColumnRowOffsetTo(entity, col, row, rotated) {
        if (col == 0 && row == 0) {
            return;
        }
        entity.move(this.getColumnRowOffset(col, row, rotated));
    }
    isArrayRect() {
        return (this.columnCount != 1 || this.rowCount != 1);
    }
    _render(canvas, blockname, entity, main, info) {
        let isArray = this.isArrayRect();
        for (let col = 0; col < this.columnCount; col++) {
            for (let row = 0; row < this.rowCount; row++) {
                //获取到当前块
                let offset = this.getColumnRowOffset(col, row, true);
                let pos = this.position.moveNewPoint(offset.x, offset.y);
                canvas.drawBlock(entity, blockname, pos, this.getRotate(), main, info);
            }
        }
    }
    render(canvas, entity, main, info, graphics) {
        let block = canvas.map.dwgservice.getBlock(this.blockName);
        if (block != null) {
            entity.startDraw(canvas, main, graphics);
            canvas.save();
            entity._drawBlock(entity, this.blockName, canvas, this._blockInfo, graphics);
            canvas.setColor(entity.entityColor(canvas.map), entity.getLayerName());
            canvas.scale(this.scaleFactors.x, this.scaleFactors.y);
            //判断是否是array
            if (this._blockInfo != null) {
                this._blockInfo.offset = this._offset;
            }
            if (this._attribute !== null) {
                this._blockInfo.attribute = this._attribute;
            }
            this._render(canvas, this.blockName, entity, main, this._blockInfo);
            if (this._attribute !== null) {
                this._blockInfo.attribute = null;
            }
            canvas.restore();
            entity.endDraw(canvas, main);
        }
        else {
            entity.startDraw(canvas, main, graphics);
            canvas.save();
            canvas.setStrokeWidth(1);
            canvas.scale(this.scaleFactors.x, this.scaleFactors.y);
            canvas.setRotate(entity.subData().getRotate());
            let pos = entity.subData().position;
            canvas.drawPoint(pos.x, pos.y);
            canvas.restore();
            entity.endDraw(canvas, main);
        }
        return;
    }
    mirror(axis) {
        this.position.mirror(axis);
        if (this._attribute !== null) {
            this._attribute.mirror(axis);
        }
        let vec = ltpoint_1.LTPoint.createPolar(1.0, this._rotate);
        vec.mirror(new RLine(new ltpoint_1.LTPoint(0.0, 0.0), axis.getEndPoint().minus(axis.getStartPoint())));
        this._rotate = vec.getAngle();
        this.scaleFactors.y *= -1;
    }
}
exports.RBlockReference = RBlockReference;
